/** @format */

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-failure-count-label',
    templateUrl: './failure-count-label.component.html',
    styleUrls: ['./failure-count-label.component.scss'],
    standalone: false
})
export class FailureCountComponent {
  @Input('value')
  set setValue(value: number | null | undefined) {
    this.value = value === undefined ? 0 : value;
  }
  value: number | null;
}
