/** @format */

import { Injectable, Type } from '@angular/core';
import { merge, set } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment, { Moment } from 'moment';
import { EquipmentFailure } from '../../_classes/equipment-failure/equipment-failure.class';
import { EquipmentFailureElevator } from '../../_classes/equipment-failure/equipment-failure.elevator.class';
import { FieldUpdateOrigin } from '../../_classes/field-update-origin.class';
import { EquipmentFamily } from '../../_constants/equipment/equipment-family';
import { CrudServiceBuild, ICrudService } from '../crud.service';

export type PeriodFailureInfo = {
  count: number;
  duration: number; // in milliseconds
  disponibility: number;
};

export type FailureInfoData = { start: number; end: number; value: any; origin: FieldUpdateOrigin };
export type FailureInfo = {
  equipmentStatuses: FailureInfoData[];
  signatureStatuses: FailureInfoData[];
};

export interface IEquipmentFailureFilter {
  search?: string;
  equipmentId?: string | null;
  from?: Moment | Date;
  to?: Moment | Date;
  getIgnored?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentFailureService extends ExtendsEquipmentFailureService<EquipmentFailure, IEquipmentFailureFilter>(
  EquipmentFailure,
) {}

export interface IEquipmentFailureService<TClass extends EquipmentFailure, ReqFilter extends IEquipmentFailureFilter>
  extends ICrudService<TClass, ReqFilter> {
  family?: EquipmentFamily;
  duration(filter: ReqFilter): Promise<number>;
  getPeriodInfo(filter: ReqFilter): Promise<PeriodFailureInfo>;
  getInfo(_id: string): Promise<FailureInfo>;
  toggleIgnored(_id: string): Promise<boolean>;
}

export function ExtendsEquipmentFailureService<
  TClass extends EquipmentFailure,
  ReqFilter extends IEquipmentFailureFilter,
>(target: Type<TClass>, family?: EquipmentFamily): Type<IEquipmentFailureService<TClass, ReqFilter>> {
  const path = family ? `/equipment-failure/${family}` : '/equipment-failure';
  class EquipmentFailureServiceClass
    extends CrudServiceBuild<TClass, ReqFilter>(target, path)
    implements IEquipmentFailureService<TClass, ReqFilter>
  {
    family?: EquipmentFamily = family;

    async duration(filter: ReqFilter): Promise<number> {
      const path = `/equipment-failure/duration`;
      const query = this.getFilterQuery(filter);
      return this.httpService.get(path, query);
    }

    async getPeriodInfo(filter: ReqFilter): Promise<PeriodFailureInfo> {
      const path = `/equipment-failure/period-info`;
      const query = this.getFilterQuery(filter);
      return this.httpService.get(path, query).then((data) =>
        merge(data, {
          disponibility: 1 - data.duration / (filter.to ? moment(filter.to) : moment()).diff(filter.from),
        }),
      );
    }

    async getInfo(_id: string): Promise<FailureInfo> {
      const path = `/equipment-failure/${_id}/info`;
      return this.httpService.get(path);
    }

    async toggleIgnored(_id: string): Promise<boolean> {
      const path = `/equipment-failure/${_id}/ignored`;
      return this.httpService.patch(path);
    }

    override setFilterQueryEntry(filter: IEquipmentFailureFilter, query: any, key: string, value: any): void {
      switch (key) {
        case 'from':
        case 'to':
          set(query, key, value.utc(true).toISOString());
          break;
        default:
          set(query, key, value);
      }
    }

    override buildType(data: any): TClass {
      const familyType = family || data.family;
      switch (familyType as EquipmentFamily) {
        case EquipmentFamily.ELEVATOR:
          return new ModelMapper(EquipmentFailureElevator).map(data) as any;
        default:
          return new ModelMapper(EquipmentFailure).map(data) as any;
      }
    }
  }
  return EquipmentFailureServiceClass as any;
}
