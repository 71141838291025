/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AppLoaderModule } from '../loader/loader.module';
import { ChartRangeSelectorComponent } from './chart-range-selector/chart-range-selector.component';
import { ChartComponent } from './chart/chart.component';
import { FieldHistoryChartComponent } from './field-history-chart/field-history-chart.component';
import { HousingChartComponent } from './housing-chart/housing-chart.component';
import { MouvementChartComponent } from './mouvement-chart/mouvement-chart.component';
import { MultiFieldHistoryChartComponent } from './multi-field-history-chart/multi-field-history-chart.component';
import { WeatherChartComponent } from './weather-chart/weather-chart.component';

const charts = [
  ChartComponent,
  ChartRangeSelectorComponent,
  FieldHistoryChartComponent,
  WeatherChartComponent,
  HousingChartComponent,
  MultiFieldHistoryChartComponent,
  MouvementChartComponent,
];

@NgModule({
  declarations: charts,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppFlexLayoutModule,
    AppLoaderModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    NgScrollbarModule,
    ExtentedPipesModule,
    IntersectionObserverModule,
    MatExpansionModule,
  ],
  exports: charts,
})
export class ChartModule {}
