/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { find, isEqual, reduce } from 'lodash-es';
import { SubSink } from 'subsink';
import { SearchService } from '../../../_services/search.service';
import { SessionService } from '../../../_services/session.service';
import { IMarker, MapComponent } from '../map.component/map.component';

export type Filter = {
  bounds: [[number, number], [number, number]];
};

export type IndicatorOption = {
  value: any;
  color: string;
  icon: string;
};

export type MapInfo = {
  _id: string;
  indicator: any;
  coordinates: [number, number] | [number, number, number];
  date?: Date;
};

@Component({
  selector: 'app-indicator-map',
  templateUrl: './indicator-map.component.html',
  styleUrls: ['./indicator-map.component.scss'],
  standalone: false,
})
export class IndicatorMapComponent implements OnInit, OnDestroy {
  @Input()
  indicatorOptions: IndicatorOption[];

  @Input()
  service: (filter: Filter) => Promise<MapInfo[]>;

  mapOptions: any;
  bounds: mapboxgl.LngLatBounds | null = null;
  private mapRef: MapComponent;
  private subsink = new SubSink();

  constructor(
    private sessionService: SessionService,
    private searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.mapOptions = {
      maxZoom: 30,
      cluster: true,
      enableNavControl: true,
      clusterProperties: {
        ...reduce(
          this.indicatorOptions,
          (pv, indicator) => {
            pv[indicator.value] = {
              color: indicator.color,
              merge: ['+', ['case', ['==', ['get', 'indicator'], indicator.value], 1, 0]],
            };
            return pv;
          },
          {} as any,
        ),
      },
    };
    this.subsink.add(
      this.sessionService.$organization.subscribe(() => this.load()),
      this.searchService.$search.subscribe((value) => this.load()),
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  private boundsTm: any;
  async loadMap(mapRef: MapComponent) {
    this.mapRef = mapRef;
    this.subsink.add(
      this.mapRef.movedEmitter.subscribe((event): any => {
        const bounds = this.mapRef.getBounds();
        if (bounds && (!this.bounds || !isEqual(this.bounds.toArray(), bounds.toArray()))) {
          this.bounds = bounds;
        }
        if (this.boundsTm) clearTimeout(this.boundsTm);
        this.boundsTm = null;
      }),
    );
    this.loadMapData();
  }

  reset() {
    this.bounds = null;
    this.load();
  }

  private load() {
    this.loadMapData();
  }

  private async loadMapData() {
    if (!this.mapRef) return;
    this.mapRef.isLoading = true;
    const data = await this.service(this.getFilter(true));
    const markers: IMarker[] = [];
    const coordinates: [number, number][] = [];
    for (let d of data) {
      if (d.coordinates?.length < 2) continue;
      const marker = this.buildMarker(d);
      if (!marker) continue;
      markers.push(marker);
      coordinates.push([d.coordinates[0], d.coordinates[1]]);
    }
    this.mapRef.setMarkers(markers);
    this.mapRef.fitBounds(coordinates, { padding: 64 });
    this.mapRef.isLoading = false;
  }

  private buildMarker(d: {
    coordinates: [number, number] | [number, number, number];
    indicator: any;
  }): IMarker | undefined {
    const indicator = find(this.indicatorOptions, { value: d.indicator });
    if (!indicator) return;
    return {
      type: 'icon',
      icon: indicator.icon,
      position: [d.coordinates[0], d.coordinates[1]],
      color: indicator.color,
      properties: d,
    };
  }

  private getFilter(omitBounds = false): any {
    const filter: any = {};
    if (!omitBounds && this.bounds) filter.bounds = this.bounds.toArray() as [[number, number], [number, number]];
    return filter;
  }
}
