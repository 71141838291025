/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { Elevator } from '../../_classes/equipment/equipment.elevator.class';

@Pipe({
    name: 'elevatorFloor',
    pure: false,
    standalone: false
})
export class ElevatorFloorPipe implements PipeTransform {
  constructor() {}

  transform(elevator: Elevator, currentFloorIndex: number | undefined): string | undefined {
    if (!elevator.metadata?.floors) return;
    if (!isNil(currentFloorIndex)) {
      if (Number.isInteger(currentFloorIndex)) {
        return elevator.metadata.floors[currentFloorIndex]?.name;
      }
      return `${elevator.metadata.floors[Math.floor(currentFloorIndex)]?.name} | ${
        elevator.metadata.floors[Math.ceil(currentFloorIndex)]?.name
      }`;
    }
    return '';
  }
}
