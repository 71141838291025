/** @format */

import { Component, Input } from '@angular/core';
import { EquipmentFloorKind } from '../../_constants/equipment-floor-kind';

@Component({
    selector: 'app-equipment-floor-kind',
    templateUrl: './equipment-floor-kind.component.html',
    styleUrls: ['./equipment-floor-kind.component.scss'],
    standalone: false
})
export class EquipmentFloorKindComponent {
  valueEnum = EquipmentFloorKind;

  @Input()
  value: EquipmentFloorKind;

  @Input()
  light: string;

  constructor() {}
}
