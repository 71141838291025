<!-- @format -->

<mat-tab-group
  id="content"
  *ngIf="groupedIndicators"
  mat-align-tabs="start"
  fill
  sticky-header
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  <mat-tab i18n-label label="Moyenne">
    <ng-template matTabContent>
      <ng-container
        *ngTemplateOutlet="
          weekInfoTmpl;
          context: { data: groupedIndicators['__day-of-week__'][-1], average: groupedIndicators['__day__'][-1][-1] }
        "
      ></ng-container>
    </ng-template>
  </mat-tab>
  <mat-tab *ngFor="let month of MONTHS; index as index" [label]="month">
    <ng-template matTabContent>
      <ng-container
        *ngTemplateOutlet="weekInfoTmpl; context: { data: groupedIndicators['__day-of-month__'][index + 1] }"
      ></ng-container>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="elevator.holidays?.length" i18n-label label="Jours fériés">
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="holidaysInfoTmpl"></ng-container>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<app-loader *ngIf="!groupedIndicators" @fade class="block-loader" diameter="100px"></app-loader>

<ng-template #weekInfoTmpl let-data="data" let-average="average">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start start" [style.min-width.%]="100">
      <div id="column-data-info" class="mat-elevation-z4" fxLayout="row">
        <mat-grid-list [cols]="average ? 33 : 29" rowHeight="823" gutterSize="16" fxFlex="grow">
          <mat-grid-tile>
            <mat-grid-list cols="1" rowHeight="32" fxFlex="grow">
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile *ngFor="let hour of HOURS">
                <span color="primary">{{ hour }}h</span>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="average" colspan="4">
            <ng-container
              *ngTemplateOutlet="columnDayInfoTmpl; context: { data: average, name: AVERAGE_NAME }"
            ></ng-container>
          </mat-grid-tile>
          <mat-grid-tile *ngFor="let day of DAY_ORDER" colspan="4">
            <ng-container
              *ngTemplateOutlet="columnDayInfoTmpl; context: { data: data[day], name: DAYS[day - 1] + 's' }"
            ></ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dayInfoTmpl let-data="data">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start start" [style.min-width.%]="100">
      <div id="column-data-info" class="mat-elevation-z4" fxLayout="row">
        <mat-grid-list cols="29" rowHeight="823" gutterSize="16" fxFlex="grow">
          <mat-grid-tile>
            <mat-grid-list cols="1" rowHeight="32" fxFlex="grow">
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile *ngFor="let hour of HOURS">
                <span color="primary">{{ hour }}h</span>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-grid-tile>
          <mat-grid-tile colspan="4">
            <ng-container *ngTemplateOutlet="columnDayInfoTmpl; context: { data: data }"></ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #holidaysInfoTmpl>
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start start" [style.min-width.%]="100">
      <div id="column-data-info" class="mat-elevation-z4" fxLayout="row">
        <mat-grid-list [cols]="1 + elevator.holidays.length * 4" rowHeight="823" gutterSize="16" fxFlex="grow">
          <mat-grid-tile>
            <mat-grid-list cols="1" rowHeight="32" fxFlex="grow">
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile *ngFor="let hour of HOURS">
                <span color="primary">{{ hour }}h</span>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-grid-tile>
          <mat-grid-tile *ngFor="let holiday of elevator.holidays" colspan="4">
            <ng-container
              *ngTemplateOutlet="
                columnDayInfoTmpl;
                context: { data: groupedIndicators[holiday.name][-1][-1], name: holiday.name }
              "
            ></ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #columnDayInfoTmpl let-name="name" let-data="data">
  <mat-grid-list cols="4" rowHeight="32" fxFlex="grow">
    <mat-grid-tile colspan="4">
      <div fxLayout="column" [style.width.%]="100" [style.line-height.em]="1">
        <span *ngIf="name" color="primary">{{ name }}</span>
      </div>
    </mat-grid-tile>

    <ng-container *ngFor="let hour of HOURS">
      <ng-container *ngFor="let minute of MINUTES">
        <mat-grid-tile>
          <ng-container *ngTemplateOutlet="indicatorCellTmpl; context: { data: data[hour][minute] }"> </ng-container>
        </mat-grid-tile>
      </ng-container>
    </ng-container>
  </mat-grid-list>
</ng-template>

<ng-template #indicatorCellTmpl let-data="data">
  <div
    class="column-day-info-row-cell"
    fxLayoutAlign="center center"
    [class.no-mouvements]="!data?.mouvements"
    [style.background]="data?.color"
    [style.--data-percentil]="data?.percentil"
    [style.--data-mouvements]="data?.mouvements"
    [matTooltip]="data?.info"
  >
    <span class="round" *ngIf="(data | get: 'mouvements' : 0 | round) >= 1">{{
      data | get: 'mouvements' : 0 | round
    }}</span>
    <span class="hover">{{ data | get: 'mouvements' : 0 | number: '1.0-2' }}</span>
  </div>
</ng-template>
