/** @format */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { isEqual, keys, map as lmap, merge, reduce } from 'lodash-es';
import { SubSink } from 'subsink';
import { EquipmentFamily, EquipmentFamilyName } from '../../../_constants/equipment/equipment-family';
import {
  EquipmentStatus,
  EquipmentStatusColorName,
  EquipmentStatusDatagridOptions,
} from '../../../_constants/equipment/equipment-status';
import { SearchKey } from '../../../_constants/search';
import { getColorValue } from '../../../_helpers/style.helper';
import { EquipmentService, IEquipmentFilter } from '../../../_services/equipment/equipment.service';
import { NavigationService } from '../../../_services/navigation.service';
import { SearchService } from '../../../_services/search.service';
import { SessionService } from '../../../_services/session.service';
import { DatagridComponent, IDatagridOptions } from '../../datagrid/datagrid.component';
import { EquipmentStatusDialogComponent } from '../../equipment-status-dialog/equipment-status-dialog.component';
import { LocationAddressCellComponent } from '../../location-address-cell/location-address-cell.component';
import { IMarker, MapComponent } from '../map.component/map.component';

export interface IElement {
  _id: string;
  reference: string;
  status: EquipmentStatus;
  family: EquipmentFamily;
  kindId: string;
  categoryId: string;
  organizationalUnitId: string;
  realEstateStructureId: string;
  icon: string;
}

interface IStatusOption {
  status: EquipmentStatus;
  icon: string;
  name: string;
  color: string;
  count: number;
  selected: boolean;
}

@Component({
  selector: 'app-equipment-status-map',
  templateUrl: './equipment-status-map.component.html',
  styleUrls: ['./equipment-status-map.component.scss'],
  standalone: false,
})
export class EquipmentStatusMapComponent implements OnInit, OnDestroy {
  EquipmentStatus = EquipmentStatus;
  EquipmentFamilyName = EquipmentFamilyName;

  @Input()
  families: EquipmentFamily[];

  @Input()
  buildCommands: (info: IElement) => Promise<any[]>;

  @Input()
  buildQueryParams: (info: IElement) => Promise<any>;

  mapOptions = {
    maxZoom: 19,
    cluster: true,
    enableNavControl: true,
    clusterProperties: {
      ...reduce(
        keys(EquipmentStatusColorName),
        (pv, key) => {
          pv[key] = {
            color: `var(--app-color-${EquipmentStatusColorName[key]})`,
            merge: ['+', ['case', ['==', ['get', 'status'], key], 1, 0]],
          };
          return pv;
        },
        {} as any
      ),
    },
    popup: (markers: IMarker[]) => {
      this.openDialog(lmap(markers, (m: any) => merge({ icon: m.icon }, m.properties)));
    },
    click: (markers: IMarker[]) => {
      this.openDialog(lmap(markers, (m: any) => merge({ icon: m.icon }, m.properties)));
    },
  };

  @ViewChild('drawer', { read: MatDrawer })
  drawerRef: MatDrawer;

  datagridOptions: IDatagridOptions = {
    pagination: { default: 1, options: [10, 20, 50] },
    service: query => this.equipmentService.datatable(query, this.getFilter()),
    columns: [
      {
        property: 'status',
        label: $localize`Statut`,
        sortable: true,
        type: 'select',
        maxWidth: 120,
        hideName: true,
        options: EquipmentStatusDatagridOptions,
      },
      {
        property: 'reference',
        label: $localize`Référence`,
        sortable: true,
      },
      {
        property: 'organizationalUnit',
        label: $localize`Unité organisationnelle`,
        sortable: true,
        cellTmpl: 'organizational-unit',
      },
      {
        property: 'realEstateStructure',
        label: $localize`Structure immobilière`,
        sortable: true,
        cellTmpl: 'real-estate-structure',
      },
      LocationAddressCellComponent.getDatagridColumn(),
      { hidden: true, property: 'coordinates' },
    ],
    rowClick: (datagrid, record: any) => this.openDialog([record]),
  };
  @ViewChild(DatagridComponent)
  private datagridRef: DatagridComponent;

  mapZoomed = false;
  bounds: mapboxgl.LngLatBounds | null = null;
  private mapRef: MapComponent;
  private subsink = new SubSink();

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private sessionService: SessionService,
    private searchService: SearchService,
    private equipmentService: EquipmentService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subsink.add(
      this.sessionService.$organization.subscribe(() => this.load()),
      this.searchService.$search.subscribe(value => this.load())
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  toggleSearchStatus(option: IStatusOption) {
    const value = option.status;
    if (option.selected) {
      this.searchService.removeSearchItem(SearchKey.EQUIPMENT_STATUS, value);
      option.selected = false;
    } else {
      this.searchService.addSearchItem(
        this.searchService.buildSearchOption({ key: SearchKey.EQUIPMENT_STATUS, value })
      );
      option.selected = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  private boundsTm: any;
  async loadMap(mapRef: MapComponent) {
    this.mapRef = mapRef;
    this.subsink.add(
      this.mapRef.movedEmitter.subscribe((event): any => {
        const bounds = this.mapRef.getBounds();
        if (bounds && (!this.bounds || !isEqual(this.bounds.toArray(), bounds.toArray()))) {
          this.bounds = bounds;
          if (this.boundsTm) clearTimeout(this.boundsTm);
          this.boundsTm = setTimeout(() => {
            this.boundsTm = null;
            if (!this.mapZoomed) this.datagridRef?.loadPage();
          }, 300);
        }
      })
    );
    this.loadMapData();
  }

  reset() {
    this.bounds = null;
    this.load();
  }

  toggleMapZoom() {
    this.mapZoomed = !this.mapZoomed;
  }

  async navigateTo(element: IElement) {
    let commands: any[];
    if (typeof this.buildCommands === 'function') commands = await this.buildCommands(element);
    else commands = [element._id];
    let queryParams: any;
    if (typeof this.buildQueryParams === 'function') queryParams = await this.buildQueryParams(element);
    else queryParams = {};
    this.navigationService.navigate(commands, { queryParams, relativeTo: this.route });
  }

  private load() {
    if (!this.sessionService.$organization.value) return;
    this.loadDatagrid();
    this.loadMapData();
  }

  loadDatagridTm: any;
  private loadDatagrid() {
    if (this.loadDatagridTm) clearTimeout(this.loadDatagridTm);
    this.loadDatagridTm = setTimeout(() => this.datagridRef?.loadPage(), 300);
  }

  private async loadMapData() {
    if (!this.mapRef) return;
    this.mapRef.isLoading = true;
    const data = await this.equipmentService.getMapInfo(this.getFilter(true));
    const markers: IMarker[] = [];
    const coordinates: [number, number][] = [];
    for (let d of data) {
      if (d.coordinates?.length !== 2) continue;
      markers.push({
        type: 'icon',
        icon: `assets/icons/equipment-status/equipment-status-${d.status}.png`,
        position: d.coordinates,
        color: getColorValue(EquipmentStatusColorName[d.status]),
        properties: d,
      });
      coordinates.push(d.coordinates);
    }
    console.warn('markers', markers);
    this.mapRef.setMarkers(markers);
    this.mapRef.fitBounds(coordinates, { padding: 64 });
    this.mapRef.isLoading = false;
  }

  private getFilter(omitBounds = false): IEquipmentFilter {
    const filter: IEquipmentFilter = { families: this.families, searchItems: this.searchService.$search.value };
    if (!omitBounds && this.bounds) filter.bounds = this.bounds.toArray() as [[number, number], [number, number]];
    return filter;
  }

  private openDialog(elements: IElement[]) {
    this.dialog.open(EquipmentStatusDialogComponent, {
      panelClass: ['dialog-no-background'],
      data: { elements, navigateTo: (element: IElement) => this.navigateTo(element) },
    });
  }
}
