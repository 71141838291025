/** @format */

import { ModelMapper, PropertyMap } from 'model-mapper';
import { EquipmentFamily } from '../_constants/equipment/equipment-family';
import { EquipmentStatus } from '../_constants/equipment/equipment-status';
import { ElevatorLastDoorStates } from './equipment/equipment.elevator.class';
import { FieldUpdateOrigin } from './field-update-origin.class';
import { Location } from './location.class';

export enum UserNotificationKind {
  EQUIPMENT_STATUS = 'equipment-status',
}

export class UserNotificationUser {
  sub: string;
  email: string;
}

export class EquipmentStatusUserNotificationContext {
  @PropertyMap()
  organization: string;

  @PropertyMap()
  _id: string;

  @PropertyMap()
  family: EquipmentFamily;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  status: EquipmentStatus;

  @PropertyMap()
  previousStatus: EquipmentStatus;

  @PropertyMap()
  origin: FieldUpdateOrigin;

  @PropertyMap({ type: Date })
  updatedAt: Date;

  @PropertyMap()
  duration: number;

  @PropertyMap()
  kindName: string;

  @PropertyMap()
  address: string;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap()
  lastPosition?: number;

  @PropertyMap()
  lastPositionName?: string;

  @PropertyMap({ type: ElevatorLastDoorStates })
  lastDoorStates?: ElevatorLastDoorStates;

  @PropertyMap()
  alertEndpoint?: string;

  @PropertyMap()
  alertStatuses?: EquipmentStatus[];

  @PropertyMap()
  contacts: { sub: string; email: string; statuses: EquipmentStatus[] }[];
}

export class UserNotification {
  @PropertyMap()
  id: string;

  @PropertyMap({ type: UserNotificationUser })
  user: UserNotificationUser;

  @PropertyMap()
  kind: UserNotificationKind;

  @PropertyMap({ type: Date })
  time: Date;

  @PropertyMap()
  read: boolean;

  @PropertyMap({
    map: (source, value) => {
      if (!value) return null;
      const contextData = JSON.parse(value);
      switch (source.kind) {
        case UserNotificationKind.EQUIPMENT_STATUS:
          return new ModelMapper(EquipmentStatusUserNotificationContext).map(contextData);
        default:
          return contextData;
      }
    },
  })
  context?: any;
}
