<div id="floors-view" fxLayout="row" fxLayoutAlign="end start">
  <app-elevator-scene [elevator]="elevator" [currentSpeed]="currentSpeed" [direction]="direction"
    [currentFloorIndex]="currentFloorIndex" [doorState]="doorState" [style.height.px]="floors!.length * 55">
  </app-elevator-scene>
  <div id="floors" fxLayout="column">
    <div class="floor" *ngFor="let floor of floors | reverse; index as index" fxLayoutAlign="start center"
      [class.doubt-removal]="floor.sesioNodeComponentId"
      [class.current]="(floors.length - index - 1) === currentFloorIndex">
      <div class="floor-name" fxLayout="column" fxLayoutAlign="center center" (click)="sendTo(floor)" matRipple
        [matRippleDisabled]="!floor.sesioNodeComponentId" i18n-matTooltip
        matTooltip="Niveau {{floor.shortName}}, Distance: {{floor.distance | number:'0.0-0'}} cm">
        <span>{{floor.shortName}}</span>
      </div>
      <span class="floor-info" *ngIf="floor.sesioNodeComponentId" i18n>Levée de doute</span>
    </div>
    <div id="current-floor" *ngIf="!(currentFloorIndex | isNil)" fxLayoutAlign="center start"
      [style.bottom.px]="currentFloorIndex! * 55" [style.background-color]="elevator.status| equipmentStatus:'color'">
      <span id="current-speed" i18n>{{currentSpeed | number:'0.0-2'}} m/s</span>
    </div>
  </div>
</div>
