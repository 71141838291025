/** @format */

/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';

import { Amplify, Auth } from 'aws-amplify';
import aws_exports from './aws-exports';

import { BrowserOptions } from '@sentry/angular';
import { includes } from 'lodash-es';
import sentryInfo from './sentry-info';

environment.apiName = environment.apiName || 'sesiorest';
environment.region = aws_exports['aws_project_region'];
const endpoint = environment.env === 'locale' ? 'http://localhost:3001' : `https://${environment.env}-api.sesio.io`;
aws_exports['aws_cloud_logic_custom'] = [
  {
    name: environment.apiName,
    endpoint,
    region: environment.region,
    custom_header: async () => {
      const [jwtToken, cognitoIdentityId] = await Promise.all([
        Auth.currentSession().then((session) => session.getAccessToken().getJwtToken()),
        Auth.currentCredentials().then((credentials) => credentials.identityId),
      ]);
      return { 'jwt-token': jwtToken, 'cognito-identity-id': cognitoIdentityId };
    },
  },
];
Amplify.configure(aws_exports);

const sentryExclusionRejection = [
  'The user is not authenticated', // not authenticated
  "Cannot read properties of null (reading 'dispose')", // aframe error on dispose text component - create fix release ?
  'network error',
  'AMQJS0011E Invalid state not connected.',
];
const sentryConfig: BrowserOptions = {
  debug: false,
  dsn: 'https://aa2aadaad7ac4013622043d5570add42@o4506081162166272.ingest.sentry.io/4506082455388160',
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: aws_exports['aws_cloud_logic_custom'].map((logic: any) => logic.endpoint),
  environment: environment.env,
  release: environment.env === 'locale' ? undefined : sentryInfo.release,
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (environment.env === 'locale') return null;
    if (event.exception && hint.originalException) {
      console.debug('sentry error', { event, hint });
      const originalException: any = hint.originalException as any;
      const rejection = originalException.rejection;
      if (includes(sentryExclusionRejection, originalException.message)) return null;
      if (includes(sentryExclusionRejection, typeof rejection === 'string' ? rejection : rejection?.message)) {
        return null;
      }
      if (includes([401, 403, 404], originalException?.status)) return null;
      if (includes([401, 403, 404], rejection?.response?.status)) return null;
    }
    return event;
  },
};
Sentry.init(sentryConfig);

if (environment.production) {
  enableProdMode();
} else {
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';
  // (window as any).process = { env: { DEBUG: undefined } };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
