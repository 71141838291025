/** @format */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { get, head } from 'lodash-es';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import createPanZoom, { PanZoom, Transform } from 'panzoom';

interface ISize {
  height: number;
  width: number;
}

@Component({
    selector: 'app-panzoom',
    templateUrl: './panzoom.component.html',
    styleUrls: ['./panzoom.component.scss'],
    standalone: false
})
export class PanzoomComponent implements OnInit, OnDestroy {
  @ViewChild('container')
  containerRef: ElementRef;

  @ViewChild('content')
  contentRef: ElementRef;

  @ViewChild('pan')
  panRef: ElementRef;

  panzoom: PanZoom;

  @Input('pause')
  set setPause(pause: boolean) {
    this.pause = pause;
    this.togglePause();
  }
  pause = false;

  @Output('transform')
  private transformEmitter = new EventEmitter<Transform>();

  cursor: 'grab' | 'grabbing' | 'zoom-in' = 'grab';

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent): void {
    if ($event.ctrlKey || $event.metaKey) {
      this.cursor = 'zoom-in';
      this.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('window:keyup')
  onKeyUp(): void {
    this.cursor = 'grab';
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('document:mousedown')
  onMouseDown(): void {
    this.cursor = 'grabbing';
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('document:mouseup')
  onMouseUp(): void {
    this.cursor = 'grab';
    this.changeDetectorRef.detectChanges();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.panzoom?.dispose();
  }

  load(event: IntersectionObserverEntry[]) {
    if (get(head(event), 'isIntersecting') && !this.panzoom) {
      this.panzoom = createPanZoom(this.panRef.nativeElement, {
        beforeWheel: (e) => !e.ctrlKey && !e.metaKey,
      });
      this.panzoom.on('transform', (e) => this.transformEmitter.emit(this.panzoom.getTransform()));
      this.togglePause();
    }
  }

  private size: ISize;
  private containerSize: ISize;
  private ratio: ISize;
  rezise() {
    if (this.panzoom) {
      const transform = this.panzoom.getTransform();
      this.size = {
        height: this.panRef.nativeElement.firstChild.clientHeight * transform.scale,
        width: this.panRef.nativeElement.firstChild.clientWidth * transform.scale,
      };
      this.containerSize = {
        height: this.containerRef.nativeElement.clientHeight,
        width: this.containerRef.nativeElement.clientWidth,
      };
      this.ratio = {
        height: this.containerSize.height / this.size.height,
        width: this.containerSize.width / this.size.width,
      };
      const scale = Math.min(this.ratio.height, this.ratio.width);
      const x = (this.containerSize.width - this.size.width * scale) / 2;
      const y = (this.containerSize.height - this.size.height * scale) / 2;
      this.panzoom.zoomTo(0, 0, scale);
      this.panzoom.moveTo(x, y);
    }
  }

  private togglePause() {
    if (this.panzoom) {
      if (this.pause) this.panzoom.pause();
      else this.panzoom.resume();
    }
  }
}
