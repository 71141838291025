/** @format */

import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ceil, each, filter, get, includes, map as lmap, max, merge, min, sum } from 'lodash-es';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { Elevator, ElevatorFloor } from '../../../_classes/equipment/equipment.elevator.class';
import { EmbeddedSesioNodeComponent } from '../../../_classes/sesio-node-component/sesio-node-component.embedded.class';
import { EquipmentDoorState, getEquipmentDoorState } from '../../../_constants/equipment-door/equipment-door-state';
import { SesioNodeComponentKind } from '../../../_constants/sesio-node-component/sesio-node-component-kind';
import { getBaseFloor, getFloorIndex } from '../../../_helpers/equipment.helper';
import { AppLogger } from '../../../_services/logger.service';
import { IDoorEvent, ILidarEvent } from '../../../_services/mqtt/node-mqtt.interface';
import { NodeMqttService } from '../../../_services/mqtt/node.mqtt.service';
import { SesioNodeComponentCabinCallGpioService } from '../../../_services/sesio-node-component/sesio-node-component.cabin-call-gpio.service';

interface ILidarData {
  time: number;
  speed: number;
  distance: number;
  direction: number;
  since: number;
  floorIndex?: number;
}

@Component({
    selector: 'app-elevator-live-2d',
    templateUrl: './elevatorlive2d.component.html',
    styleUrls: ['./elevatorlive2d.component.scss'],
    standalone: false
})
export class Elevatorlive2dComponent implements OnInit, OnDestroy {
  EquipmentDoorState = EquipmentDoorState;

  loading = true;

  @Input()
  elevator: Elevator;

  @Input()
  components: EmbeddedSesioNodeComponent[];

  floors: ElevatorFloor[];

  private subsink = new SubSink();
  private logger = new AppLogger('Elevatorlive2dComponent');

  private sesioNodeComponentCabinCallGpioService = inject(SesioNodeComponentCabinCallGpioService);
  private nodeMqttService = inject(NodeMqttService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy(): void {
    this.clearLiveSubscription();
    if (this.liveInterval) clearInterval(this.liveInterval);
    this.subsink.unsubscribe();
  }

  isSendingTo = false;
  async sendTo(floor: ElevatorFloor) {
    if (!floor.sesioNodeComponentId || this.isSendingTo) return;
    this.isSendingTo = true;
    this.logger.debug('sendTo', floor);
    if (floor.sesioNodeComponentId) {
      await this.sesioNodeComponentCabinCallGpioService.trigger(floor.sesioNodeComponentId, floor.cabinCall!);
    }
    this.isSendingTo = false;
  }

  speedConfig: { nominal: number; blocking: number };
  lidarData: ILidarData = { direction: 0, distance: 0, speed: 0, time: 0, since: 0 } as ILidarData;
  private load() {
    this.logger.debug('load', this.elevator);
    this.loading = true;
    this.buildFloorsData();
    this.speedConfig = {
      nominal: get(this.elevator, 'metadata.cabin.nominalSpeed', 1.2),
      blocking: get(this.elevator, 'metadata.cabin.blockingSpeed', 1.6),
    };
    const lastMouvement = get(this.elevator, 'metadata.lastMouvement');
    if (lastMouvement) {
      this.lidarData = {
        time: lastMouvement.end.valueOf(),
        distance: lastMouvement.endDistance,
        speed: 0,
        direction: 0,
        since: Date.now() - lastMouvement.end.valueOf(),
        floorIndex: getFloorIndex(this.elevator.metadata!.floors, lastMouvement.endDistance),
      } as any;
    }
    this.loadDoorData();
    this.elevatorLive();
    setTimeout(() => {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  doorData: { time: number; since: number; state: EquipmentDoorState } | undefined;
  private loadDoorData() {
    const lastDoorStates = this.elevator.metadata?.lastDoorStates;
    if (lastDoorStates) {
      const lastDoorState = lastDoorStates.getLastDoorState();
      if (lastDoorState) {
        this.doorData = {
          time: lastDoorState.date.valueOf(),
          since: Date.now() - lastDoorState.date.valueOf(),
          state: lastDoorState.state,
        };
      }
    }
  }

  floorsData: any;
  private buildFloorsData() {
    this.floors = get(this.elevator, 'metadata.floors', []);
    const base = getBaseFloor(this.floors);
    const height = sum(lmap(this.floors, 'height'));
    const minDistance = min(lmap(this.floors, 'distance')) || 0;
    const maxDistance = max(lmap(this.floors, 'distance')) || 0;
    const baseDistance = get(base, 'distance') || 0;
    const minHeight = min(lmap(this.floors, 'height')) || 0;
    const minGroundDistance = min(lmap(this.floors, 'groundDistance')) || 0;
    const blockHeight = 49 * (height / minHeight) || get(this.floors, 'length') * 49;
    const tickHeight = blockHeight / (height / 100);
    const rulerHeight = blockHeight + 100; // TODO
    const ruleBottomOffset = Math.round(minGroundDistance / 100);
    const nbTicks = ceil(rulerHeight / tickHeight);
    this.floorsData = {
      height,
      minDistance,
      maxDistance,
      baseDistance,
      tickHeight,
      rulerHeight,
      nbTicks,
      minHeight,
      minGroundDistance,
      blockHeight,
      ruleBottomOffset,
    };
  }

  private liveSubscriptions: Subscription[] = [];
  private liveInterval: any;
  private async elevatorLive() {
    this.clearLiveSubscription();
    const components = filter(this.components, (component) =>
      includes([SesioNodeComponentKind.LIDAR, SesioNodeComponentKind.DOOR_GPIO], component.kind),
    );
    each(components, (component) => {
      this.liveSubscriptions.push(
        this.nodeMqttService.getLiveTopicSubscription(component).subscribe((data: any) => {
          switch (component.kind) {
            case SesioNodeComponentKind.LIDAR:
              this.processLidarEvent(data);
              break;
            case SesioNodeComponentKind.DOOR_GPIO:
              this.processDoorEvent(data);
              break;
          }
          this.changeDetectorRef.detectChanges();
        }),
      );
      this.nodeMqttService.publishLiveAction(component);
    });
    this.liveInterval = setInterval(() => {
      if (document.visibilityState !== 'visible') return;
      each(components, (component) => this.nodeMqttService.publishLiveAction(component));
    }, 50000);
  }

  private processLidarEvent(data: ILidarEvent) {
    if (data.speed > 3) return;
    if (!this.elevator.metadata) return;
    this.lidarData = merge({}, data, {
      since: Date.now() - data.time.valueOf(),
      floorIndex: getFloorIndex(this.elevator.metadata.floors, data.distance),
      direction: data.speed && this.lidarData ? this.lidarData.distance - data.distance : 0,
    });
  }

  private processDoorEvent(data: IDoorEvent) {
    const state = getEquipmentDoorState(get(data, 'state'));
    if (!state) return;
    this.doorData = { time: data.time, since: Date.now() - data.time.valueOf(), state };
  }

  private clearLiveSubscription() {
    each(this.liveSubscriptions, (sub) => sub.unsubscribe());
    this.liveSubscriptions = [];
  }
}
