/** @format */

import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { concat, map, uniqBy } from 'lodash-es';
import moment from 'moment';
import { SubSink } from 'subsink';
import { SesioNodeComponent } from '../../../_classes/sesio-node-component/sesio-node-component.class';
import { SesioNode } from '../../../_classes/sesio-node/sesio-node.class';
import {
  ConnectivityStatusColor,
  ConnectivityStatusName,
  ConnectivityStatusOptions,
} from '../../../_constants/connectivity-status';
import {
  SesioNodeComponentStatusColor,
  SesioNodeComponentStatusOptions,
} from '../../../_constants/sesio-node-component/sesio-node-component-status';
import { SesioNodeStatusColor, SesioNodeStatusOptions } from '../../../_constants/sesio-node/sesio-node-status';
import { AuthService } from '../../../_services/auth.service';
import { SesioNodeComponentService } from '../../../_services/sesio-node-component/sesio-node-component.service';
import { SesioNodeService } from '../../../_services/sesio-node.service';

interface IData {
  nodeId: string;
  // node: SesioNode;
  // components: SesioNodeComponent[];
}

@Component({
  selector: 'app-network-control',
  templateUrl: './network-control.dialog.html',
  styleUrls: ['./network-control.dialog.scss'],
  standalone: false,
})
export class NetworkControlDialog implements OnInit, OnDestroy {
  ConnectivityStatusColor = ConnectivityStatusColor;
  ConnectivityStatusName = ConnectivityStatusName;

  componentEntities: { id: string; label: string; field: string }[];
  componentEntityCategories: { value: any; name: string; color: string }[] = uniqBy(
    concat(
      SesioNodeStatusOptions as any[],
      SesioNodeComponentStatusOptions as any[],
      ConnectivityStatusOptions as any[],
    ),
    'value',
  );

  node: SesioNode;
  components: SesioNodeComponent[];

  private sesioNodeService = inject(SesioNodeService);
  private sesioNodeComponentService = inject(SesioNodeComponentService);

  private subsink = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    public dialogRef: MatDialogRef<NetworkControlDialog>,
    public authService: AuthService,
  ) {}

  async ngOnInit() {
    [this.node, this.components] = await Promise.all([
      this.sesioNodeService.get(this.data.nodeId),
      this.sesioNodeComponentService.list({ nodeId: this.data.nodeId }),
    ]);

    this.componentEntities = [];
    this.componentEntities.push({
      id: this.node._id,
      label: $localize`<span style="font-size: 1.2em; color: ${ConnectivityStatusColor[this.node.connectivity.status]};">Connectivité du node</span><br/>${this.buildLastReportDateInfo(this.node.connectivity.statusDate)}`,
      field: 'connectivity.status',
    });
    this.componentEntities.push({
      id: this.node._id,
      label: $localize`<span style="font-size: 1.2em; color: ${SesioNodeStatusColor[this.node.status]};">Statut du node</span><br/>${this.buildLastReportDateInfo(this.node.lastStatusReportDate)}`,
      field: 'status',
    });
    this.componentEntities.push(
      ...map(this.components, (c) => ({
        id: c._id,
        label: `<span style="font-size: 1.2em; color: ${SesioNodeComponentStatusColor[c.status]};">${c.label || c.kind}</span><br/>${this.buildLastReportDateInfo(c.lastStatusReportDate)}`,
        field: 'status',
      })),
    );
  }

  private buildLastReportDateInfo(date: Date): string {
    const dateString = moment(date).format($localize`L [à] LT`);
    return $localize`<span style="font-weight: 300;">dernier rapport le ${dateString}</span>`;
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
