/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { divide, get, isNil, map, max, merge } from 'lodash-es';
import { EquipmentSignatureIndicator } from '../../../_classes/equipment-signature-indicator.class';
import { EquipmentService } from '../../../_services/equipment/equipment.service';

interface IElevatorSignatureIndicator extends EquipmentSignatureIndicator {
  percentil: number;
  color: string | null;
  info: string;
}

@Pipe({
    name: 'indicators',
    pure: false,
    standalone: false
})
export class IndicatorsPipe implements PipeTransform {
  constructor(public equipmentService: EquipmentService) {}

  async transform(equipmentId: string, name: string): Promise<IElevatorSignatureIndicator[]> {
    const data = await this.equipmentService.getSignatureIndicators(equipmentId, name);
    const maxMouvement = max(map(data, (d) => divide(get(d, 'sum'), get(d, 'nbPeriods')) || 0)) || 0;
    return map(data, (d) => {
      const mouvements = divide(get(d, 'sum'), get(d, 'nbPeriods')) || 0;
      const percentil = mouvements / maxMouvement;
      const color =
        !mouvements || mouvements === 0
          ? '#eee'
          : Math.round(mouvements) < 1
            ? null
            : percentil < 0.25
              ? '#4c91df'
              : percentil >= 0.75
                ? '#294c74'
                : '#4378b4';
      const info = `Total: ${d.sum}, Min: ${d.min}, Max: ${d.max}`;
      return merge(d, { mouvements, color, percentil, info });
    });
  }
}
