!function (e) {
  "use strict";

  let {
      THREE: t
    } = e,
    {
      EventDispatcher: n,
      Matrix4: r,
      Plane: o,
      Raycaster: s,
      Vector2: i,
      Vector3: a
    } = t;
  const c = new o(),
    l = new s(),
    d = new i(),
    p = new a(),
    u = new a(),
    m = new a(),
    v = new r();
  class h extends n {
    constructor(e, t, n) {
      super(), n.style.touchAction = "none";
      let r = null,
        o = null;
      const s = [],
        i = this;
      function a() {
        n.addEventListener("pointermove", f), n.addEventListener("pointerdown", b), n.addEventListener("pointerup", y), n.addEventListener("pointerleave", y);
      }
      function h() {
        n.removeEventListener("pointermove", f), n.removeEventListener("pointerdown", b), n.removeEventListener("pointerup", y), n.removeEventListener("pointerleave", y), n.style.cursor = "";
      }
      function f(a) {
        if (!1 !== i.enabled) {
          if (E(a), l.setFromCamera(d, t), r) return l.ray.intersectPlane(c, u) && r.position.copy(u.sub(p).applyMatrix4(v)), void i.dispatchEvent({
            type: "drag",
            object: r
          });
          if ("mouse" === a.pointerType || "pen" === a.pointerType) if (s.length = 0, l.setFromCamera(d, t), l.intersectObjects(e, !0, s), s.length > 0) {
            const e = s[0].object;
            c.setFromNormalAndCoplanarPoint(t.getWorldDirection(c.normal), m.setFromMatrixPosition(e.matrixWorld)), o !== e && null !== o && (i.dispatchEvent({
              type: "hoveroff",
              object: o
            }), n.style.cursor = "auto", o = null), o !== e && (i.dispatchEvent({
              type: "hoveron",
              object: e
            }), n.style.cursor = "pointer", o = e);
          } else null !== o && (i.dispatchEvent({
            type: "hoveroff",
            object: o
          }), n.style.cursor = "auto", o = null);
        }
      }
      function b(o) {
        var a;
        !1 !== i.enabled && (E(o), s.length = 0, l.setFromCamera(d, t), l.intersectObjects(e, !0, s), s.length > 0 && (a = s[0], r = !0 === i.transformGroup ? e[0] : !0 === i.transformDescendants ? a.object : function t(n) {
          return e.includes(n) ? n : n.parent ? t(n.parent) : null;
        }(a.object), c.setFromNormalAndCoplanarPoint(t.getWorldDirection(c.normal), m.setFromMatrixPosition(r.matrixWorld)), l.ray.intersectPlane(c, u) && (v.copy(r.parent.matrixWorld).invert(), p.copy(u).sub(m.setFromMatrixPosition(r.matrixWorld))), n.style.cursor = "move", i.dispatchEvent({
          type: "dragstart",
          object: r
        })));
      }
      function y() {
        !1 !== i.enabled && (r && (i.dispatchEvent({
          type: "dragend",
          object: r
        }), r = null), n.style.cursor = o ? "pointer" : "auto");
      }
      function E(e) {
        const t = n.getBoundingClientRect();
        d.x = (e.clientX - t.left) / t.width * 2 - 1, d.y = -(e.clientY - t.top) / t.height * 2 + 1;
      }
      a(), this.enabled = !0, this.transformGroup = !1, this.transformDescendants = !0, this.activate = a, this.deactivate = h, this.dispose = function () {
        h();
      }, this.getObjects = function () {
        return e;
      }, this.getRaycaster = function () {
        return l;
      };
    }
  }
  let f = "drag-controls";
  e.registerComponent(f, {
    schema: {
      enabled: {
        default: !0
      },
      objects: {
        default: "*"
      }
    },
    init() {
      let {
          el: e
        } = this,
        {
          camera: t,
          renderer: n
        } = e.sceneEl,
        r = new h([], t, n.domElement);
      r.transformDescendants = !1, r.addEventListener("dragstart", () => {
        e.emit(`${f}:changed`, {
          active: !0
        }, !1);
      }), r.addEventListener("dragend", () => {
        e.emit(`${f}:changed`, {
          active: !1
        }, !1);
      }), ["dragstart", "drag", "dragend", "hoveron", "hoveroff"].forEach(e => {
        r.addEventListener(e, t => {
          let {
              object: n
            } = t,
            r = n.el;
          r && r.emit(e, {
            object3D: n
          }, !1);
        });
      }), Object.assign(this, {
        camera: t,
        controls: r
      });
    },
    update() {
      let {
          controls: e,
          data: t
        } = this,
        {
          enabled: n
        } = t;
      Object.assign(e, {
        enabled: n
      });
    },
    remove() {
      this.controls.dispose();
    },
    tock() {
      let {
          controls: e,
          data: t,
          el: n
        } = this,
        {
          enabled: r,
          objects: o
        } = t;
      if (r) {
        let t = Array.from(n.sceneEl.querySelectorAll(o)),
          r = [];
        t.forEach(e => {
          e.isEntity && e.object3D && r.push(e.object3D);
        }), e.getObjects().splice(0, void 0, ...r);
      }
    }
  });
}(AFRAME);