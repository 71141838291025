/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { AwsPipesModule } from '../../_pipes/aws-pipes/aws-pipes.module';
import { DocxViewerModule } from '../docx-viewer/docx-viewer.module';
import { PanzoomModule } from '../panzoom/panzoom.module';
import { XlsxViewerModule } from '../xlsx-viewer/xlsx-viewer.module';
import { EntityDocumentViewerComponent } from './entity-document-viewer.component';

@NgModule({
  declarations: [EntityDocumentViewerComponent],
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    NgxDocViewerModule,
    DocxViewerModule,
    XlsxViewerModule,
    AwsPipesModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    PanzoomModule,
  ],
  exports: [EntityDocumentViewerComponent],
})
export class EntityDocumentViewerModule {}
