/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { Elevator } from '../../_classes/equipment/equipment.elevator.class';
import { getFloorIndex } from '../../_helpers/equipment.helper';

@Pipe({
    name: 'elevatorFloorFromDistance',
    pure: false,
    standalone: false
})
export class ElevatorFloorFromDistancePipe implements PipeTransform {
  constructor() {}

  transform(elevator: Elevator, distance: number): string {
    if (!elevator.metadata?.floors) return '';
    const currentFloorIndex = getFloorIndex(elevator.metadata.floors, distance);
    if (!isNil(currentFloorIndex)) {
      if (Number.isInteger(currentFloorIndex)) {
        return elevator.metadata.floors[currentFloorIndex]?.name;
      }
      return `${elevator.metadata.floors[Math.floor(currentFloorIndex)]?.name} <p> | </p> ${
        elevator.metadata.floors[Math.ceil(currentFloorIndex)]?.name
      }`;
    }
    return '';
  }
}
