/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Module } from './_constants/module';
import { AdminGuard } from './_guards/admin.guard';
import { ModuleGuard } from './_guards/module.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [ModuleGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'real-estate-assets',
    data: { module: Module.REAL_ESTATE_ASSETS },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./real-estate-assets/real-estate-assets-page.module').then((m) => m.RealEstateAssetsPageModule),
  },
  {
    path: 'elevator',
    data: { module: Module.ELEVATOR },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./elevator/elevator-page.module').then((m) => m.ElevatorPageModule),
  },
  {
    path: 'energy',
    data: { module: Module.ENERGY },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./energy/energy-page.module').then((m) => m.EnergyPageModule),
  },
  {
    path: 'fire-safety',
    data: { module: Module.FIRE_SAFETY },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./fire-safety/fire-safety-page.module').then((m) => m.FireSafetyPageModule),
  },
  {
    path: 'administration',
    canActivate: [AdminGuard],
    loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: '**',
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
