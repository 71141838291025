/** @format */

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { divide, each, filter, find, get, map, max, merge, reduce } from 'lodash-es';
import { EquipmentSignatureIndicator } from '../../../_classes/equipment-signature-indicator.class';
import { Elevator } from '../../../_classes/equipment/equipment.elevator.class';
import { fade } from '../../../_constants/animations';
import { EquipmentService } from '../../../_services/equipment/equipment.service';

interface IElevatorSignatureIndicator extends EquipmentSignatureIndicator {
  percentil: number;
  color: string | null;
  info: string;
}

@Component({
    selector: 'app-elevator-indicators',
    templateUrl: './elevator-indicators.component.html',
    styleUrls: ['./elevator-indicators.component.scss'],
    animations: [fade],
    standalone: false
})
export class ElevatorIndicatorsComponent implements OnInit {
  AVERAGE_NAME = $localize`Moyenne jours`;
  MONTHS = [
    $localize`Janvier`,
    $localize`Février`,
    $localize`Mars`,
    $localize`Avril`,
    $localize`Mai`,
    $localize`Juin`,
    $localize`Juillet`,
    $localize`Août`,
    $localize`Septembre`,
    $localize`Octobre`,
    $localize`Novembre`,
    $localize`Décembre`,
  ];
  DAY_ORDER = [2, 3, 4, 5, 6, 7, 1];
  DAYS = [
    $localize`Dimanche`,
    $localize`Lundi`,
    $localize`Mardi`,
    $localize`Mercredi`,
    $localize`Jeudi`,
    $localize`Vendredi`,
    $localize`Samedi`,
  ];
  HOURS = [23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
  MINUTES = [0, 15, 30, 45];

  @Input()
  elevator: Elevator;

  signatureIndicators: EquipmentSignatureIndicator[] = [];
  groupedIndicators: any;

  constructor(
    public equipmentService: EquipmentService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.signatureIndicators = await this.equipmentService.getSignatureIndicators(this.elevator._id);
    this.groupedIndicators = {
      '__day-of-month__': {},
      '__day-of-week__': { [-1]: {} },
      __day__: { [-1]: { [-1]: {} } },
      ...reduce(this.elevator.holidays, (pv, cv) => ((pv[cv.name] = { [-1]: { [-1]: {} } }), pv), {} as any),
    };
    this.buildMonthData(this.getMaxMouvement('__day-of-month__'), '__day-of-month__');
    this.buildDayOfWeekData(this.getMaxMouvement('__day-of-week__'), '__day-of-week__', -1);
    this.buildHourData(this.getMaxMouvement('__day__'), '__day__', -1, -1);
    each(this.elevator.holidays, (holiday) =>
      this.buildHourData(this.getMaxMouvement(holiday.name), holiday.name, -1, -1),
    );
    this.changeDetectorRef.detectChanges();
  }

  private getMaxMouvement(name: string): number {
    return (
      max(
        map(
          filter(this.signatureIndicators, { name }),
          (d) => divide(get(d, 'sum') || 0, get(d, 'nbPeriods') || 1) || 0,
        ),
      ) || 0
    );
  }

  private buildMonthData(maxMouvement: number, name: string) {
    each(this.MONTHS, (monthName, month) => {
      this.groupedIndicators[name][month + 1] = {};
      this.buildDayOfWeekData(maxMouvement, name, month + 1);
    });
  }

  private buildDayOfWeekData(maxMouvement: number, name: string, month: number) {
    each(this.DAY_ORDER, (dayOfWeek) => {
      this.groupedIndicators[name][month][dayOfWeek] = {};
      this.buildHourData(maxMouvement, name, month, dayOfWeek);
    });
  }

  private buildHourData(maxMouvement: number, name: string, month: number, dayOfWeek: number) {
    each(this.HOURS, (hour) => {
      this.groupedIndicators[name][month][dayOfWeek][hour] = {};
      this.buildMinuteData(maxMouvement, name, month, dayOfWeek, hour);
    });
  }

  private buildMinuteData(maxMouvement: number, name: string, month: number, dayOfWeek: number, hour: number) {
    each(this.MINUTES, (minute) => {
      this.groupedIndicators[name][month][dayOfWeek][hour][minute] = this.consolidate(
        find(this.signatureIndicators, { name, month, dayOfWeek, hour, minute }) || null,
        maxMouvement,
      );
    });
  }

  private consolidate(
    indicator: EquipmentSignatureIndicator | null,
    maxMouvement: number,
  ): IElevatorSignatureIndicator {
    const mouvements = divide(get(indicator, 'sum', 0), get(indicator, 'nbPeriods', 1)) || 0;
    const percentil = mouvements / maxMouvement;
    const color =
      !mouvements || mouvements === 0
        ? '#eee'
        : Math.round(mouvements) < 1
          ? null
          : percentil < 0.25
            ? '#4c91df'
            : percentil >= 0.75
              ? '#294c74'
              : '#4378b4';
    const info = `
      Total: ${get(indicator, 'sum', 0)},
      Min: ${get(indicator, 'min', 0)},
      Max: ${get(indicator, 'max', 0)},
      Périodes: ${get(indicator, 'nbPeriods', 0)}
    `;
    return merge(indicator, { mouvements, color, percentil, info });
  }
}
