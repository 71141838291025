import { Pipe, PipeTransform } from '@angular/core';
import { Storage } from 'aws-amplify';
import { get } from 'lodash-es';
import { Equipment } from '../../_classes/equipment/equipment.class';
import { EquipmentStatus } from '../../_constants/equipment/equipment-status';

@Pipe({
    name: 'equipmentIcon',
    standalone: false
})
export class EquipmentIconPipe implements PipeTransform {
  async transform(value: Equipment, defaultStatus?: EquipmentStatus): Promise<string | null> {
    const key = get(value, `kind.category.icons.${get(value, 'status', defaultStatus)}.key`);
    return key ? (Storage.get(key, { level: 'public' }) as Promise<string>) : null;
  }
}
