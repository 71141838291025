/*!
fflate - fast JavaScript compression/decompression
<https://101arrowz.github.io/fflate>
Licensed under MIT. https://github.com/101arrowz/fflate/blob/master/LICENSE
*/
!function (f) {
  typeof module != 'undefined' && typeof exports == 'object' ? module.exports = f() : typeof define != 'undefined' && define.amd ? define(['fflate', f]) : (typeof self != 'undefined' ? self : this).fflate = f();
}(function () {
  var _e = {};
  "use strict";
  _e.__esModule = !0;
  var t = (typeof module != 'undefined' && typeof exports == 'object' ? function (_f) {
      "use strict";

      var e;
      var r = ";var __w=require('worker_threads');__w.parentPort.on('message',function(m){onmessage({data:m})}),postMessage=function(m,t){__w.parentPort.postMessage(m,t)},close=process.exit;self=global";
      try {
        e("require('worker_threads')").Worker;
      } catch (e) {}
      exports.default = e ? function (t, n, o, s, a) {
        var u = !1,
          i = new e(t + r, {
            eval: !0
          }).on("error", function (e) {
            return a(e, null);
          }).on("message", function (e) {
            return a(null, e);
          }).on("exit", function (e) {
            e && !u && a(Error("exited with code " + e), null);
          });
        return i.postMessage(o, s), i.terminate = function () {
          return u = !0, e.prototype.terminate.call(i);
        }, i;
      } : function (e, r, t, n, o) {
        setImmediate(function () {
          return o(Error("async operations unsupported - update to Node 12+ (or Node 10-11 with the --experimental-worker CLI flag)"), null);
        });
        var s = function () {};
        return {
          terminate: s,
          postMessage: s
        };
      };
      return _f;
    } : function (_f) {
      "use strict";

      var e = eval;
      _f.default = function (r, t, n, o, s) {
        var u = e[t] || (e[t] = URL.createObjectURL(new Blob([r], {
            type: "text/javascript"
          }))),
          a = new Worker(u);
        return a.onerror = function (e) {
          return s(e.error, null);
        }, a.onmessage = function (e) {
          return s(null, e.data);
        }, a.postMessage(n, o), a;
      };
      return _f;
    })({}),
    n = Uint8Array,
    r = Uint16Array,
    e = Uint32Array,
    i = new n([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
    o = new n([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
    a = new n([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
    s = function (t, n) {
      for (var i = new r(31), o = 0; o < 31; ++o) i[o] = n += 1 << t[o - 1];
      var a = new e(i[30]);
      for (o = 1; o < 30; ++o) for (var s = i[o]; s < i[o + 1]; ++s) a[s] = s - i[o] << 5 | o;
      return [i, a];
    },
    f = s(i, 2),
    u = f[0],
    h = f[1];
  u[28] = 258, h[258] = 28;
  for (var c = s(o, 0), l = c[0], p = c[1], v = new r(32768), d = 0; d < 32768; ++d) {
    var g = (43690 & d) >>> 1 | (21845 & d) << 1;
    v[d] = ((65280 & (g = (61680 & (g = (52428 & g) >>> 2 | (13107 & g) << 2)) >>> 4 | (3855 & g) << 4)) >>> 8 | (255 & g) << 8) >>> 1;
  }
  var w = function (t, n, e) {
      for (var i = t.length, o = 0, a = new r(n); o < i; ++o) ++a[t[o] - 1];
      var s,
        f = new r(n);
      for (o = 0; o < n; ++o) f[o] = f[o - 1] + a[o - 1] << 1;
      if (e) {
        s = new r(1 << n);
        var u = 15 - n;
        for (o = 0; o < i; ++o) if (t[o]) for (var h = o << 4 | t[o], c = n - t[o], l = f[t[o] - 1]++ << c, p = l | (1 << c) - 1; l <= p; ++l) s[v[l] >>> u] = h;
      } else for (s = new r(i), o = 0; o < i; ++o) t[o] && (s[o] = v[f[t[o] - 1]++] >>> 15 - t[o]);
      return s;
    },
    y = new n(288);
  for (d = 0; d < 144; ++d) y[d] = 8;
  for (d = 144; d < 256; ++d) y[d] = 9;
  for (d = 256; d < 280; ++d) y[d] = 7;
  for (d = 280; d < 288; ++d) y[d] = 8;
  var m = new n(32);
  for (d = 0; d < 32; ++d) m[d] = 5;
  var b = w(y, 9, 0),
    x = w(y, 9, 1),
    z = w(m, 5, 0),
    k = w(m, 5, 1),
    M = function (t) {
      for (var n = t[0], r = 1; r < t.length; ++r) t[r] > n && (n = t[r]);
      return n;
    },
    A = function (t, n, r) {
      var e = n / 8 | 0;
      return (t[e] | t[e + 1] << 8) >> (7 & n) & r;
    },
    S = function (t, n) {
      var r = n / 8 | 0;
      return (t[r] | t[r + 1] << 8 | t[r + 2] << 16) >> (7 & n);
    },
    D = function (t) {
      return (t / 8 | 0) + (7 & t && 1);
    },
    C = function (t, i, o) {
      (null == i || i < 0) && (i = 0), (null == o || o > t.length) && (o = t.length);
      var a = new (t instanceof r ? r : t instanceof e ? e : n)(o - i);
      return a.set(t.subarray(i, o)), a;
    },
    U = function (t, r, e) {
      var s = t.length;
      if (!s || e && !e.l && s < 5) return r || new n(0);
      var f = !r || e,
        h = !e || e.i;
      e || (e = {}), r || (r = new n(3 * s));
      var c = function (t) {
          var e = r.length;
          if (t > e) {
            var i = new n(Math.max(2 * e, t));
            i.set(r), r = i;
          }
        },
        p = e.f || 0,
        v = e.p || 0,
        d = e.b || 0,
        g = e.l,
        y = e.d,
        m = e.m,
        b = e.n,
        z = 8 * s;
      do {
        if (!g) {
          e.f = p = A(t, v, 1);
          var U = A(t, v + 1, 3);
          if (v += 3, !U) {
            var O = t[(H = D(v) + 4) - 4] | t[H - 3] << 8,
              T = H + O;
            if (T > s) {
              if (h) throw "unexpected EOF";
              break;
            }
            f && c(d + O), r.set(t.subarray(H, T), d), e.b = d += O, e.p = v = 8 * T;
            continue;
          }
          if (1 == U) g = x, y = k, m = 9, b = 5;else {
            if (2 != U) throw "invalid block type";
            var Z = A(t, v, 31) + 257,
              I = A(t, v + 10, 15) + 4,
              F = Z + A(t, v + 5, 31) + 1;
            v += 14;
            for (var E = new n(F), G = new n(19), P = 0; P < I; ++P) G[a[P]] = A(t, v + 3 * P, 7);
            v += 3 * I;
            var j = M(G),
              _ = (1 << j) - 1;
            if (!h && v + F * (j + 7) > z) break;
            var q = w(G, j, 1);
            for (P = 0; P < F;) {
              var H,
                Y = q[A(t, v, _)];
              if (v += 15 & Y, (H = Y >>> 4) < 16) E[P++] = H;else {
                var B = 0,
                  J = 0;
                for (16 == H ? (J = 3 + A(t, v, 3), v += 2, B = E[P - 1]) : 17 == H ? (J = 3 + A(t, v, 7), v += 3) : 18 == H && (J = 11 + A(t, v, 127), v += 7); J--;) E[P++] = B;
              }
            }
            var K = E.subarray(0, Z),
              L = E.subarray(Z);
            m = M(K), b = M(L), g = w(K, m, 1), y = w(L, b, 1);
          }
          if (v > z) throw "unexpected EOF";
        }
        f && c(d + 131072);
        for (var N = (1 << m) - 1, Q = (1 << b) - 1, R = m + b + 18; h || v + R < z;) {
          var V = (B = g[S(t, v) & N]) >>> 4;
          if ((v += 15 & B) > z) throw "unexpected EOF";
          if (!B) throw "invalid length/literal";
          if (V < 256) r[d++] = V;else {
            if (256 == V) {
              g = null;
              break;
            }
            var W = V - 254;
            V > 264 && (W = A(t, v, (1 << (tt = i[P = V - 257])) - 1) + u[P], v += tt);
            var X = y[S(t, v) & Q],
              $ = X >>> 4;
            if (!X) throw "invalid distance";
            if (v += 15 & X, L = l[$], $ > 3) {
              var tt = o[$];
              L += S(t, v) & (1 << tt) - 1, v += tt;
            }
            if (v > z) throw "unexpected EOF";
            f && c(d + 131072);
            for (var nt = d + W; d < nt; d += 4) r[d] = r[d - L], r[d + 1] = r[d + 1 - L], r[d + 2] = r[d + 2 - L], r[d + 3] = r[d + 3 - L];
            d = nt;
          }
        }
        e.l = g, e.p = v, e.b = d, g && (p = 1, e.m = m, e.d = y, e.n = b);
      } while (!p);
      return d == r.length ? r : C(r, 0, d);
    },
    O = function (t, n, r) {
      var e = n / 8 | 0;
      t[e] |= r <<= 7 & n, t[e + 1] |= r >>> 8;
    },
    T = function (t, n, r) {
      var e = n / 8 | 0;
      t[e] |= r <<= 7 & n, t[e + 1] |= r >>> 8, t[e + 2] |= r >>> 16;
    },
    Z = function (t, e) {
      for (var i = [], o = 0; o < t.length; ++o) t[o] && i.push({
        s: o,
        f: t[o]
      });
      var a = i.length,
        s = i.slice();
      if (!a) return [_, 0];
      if (1 == a) {
        var f = new n(i[0].s + 1);
        return f[i[0].s] = 1, [f, 1];
      }
      i.sort(function (t, n) {
        return t.f - n.f;
      }), i.push({
        s: -1,
        f: 25001
      });
      var u = i[0],
        h = i[1],
        c = 0,
        l = 1,
        p = 2;
      for (i[0] = {
        s: -1,
        f: u.f + h.f,
        l: u,
        r: h
      }; l != a - 1;) u = i[i[c].f < i[p].f ? c++ : p++], h = i[c != l && i[c].f < i[p].f ? c++ : p++], i[l++] = {
        s: -1,
        f: u.f + h.f,
        l: u,
        r: h
      };
      var v = s[0].s;
      for (o = 1; o < a; ++o) s[o].s > v && (v = s[o].s);
      var d = new r(v + 1),
        g = I(i[l - 1], d, 0);
      if (g > e) {
        o = 0;
        var w = 0,
          y = g - e,
          m = 1 << y;
        for (s.sort(function (t, n) {
          return d[n.s] - d[t.s] || t.f - n.f;
        }); o < a; ++o) {
          var b = s[o].s;
          if (!(d[b] > e)) break;
          w += m - (1 << g - d[b]), d[b] = e;
        }
        for (w >>>= y; w > 0;) {
          var x = s[o].s;
          d[x] < e ? w -= 1 << e - d[x]++ - 1 : ++o;
        }
        for (; o >= 0 && w; --o) {
          var z = s[o].s;
          d[z] == e && (--d[z], ++w);
        }
        g = e;
      }
      return [new n(d), g];
    },
    I = function (t, n, r) {
      return -1 == t.s ? Math.max(I(t.l, n, r + 1), I(t.r, n, r + 1)) : n[t.s] = r;
    },
    F = function (t) {
      for (var n = t.length; n && !t[--n];);
      for (var e = new r(++n), i = 0, o = t[0], a = 1, s = function (t) {
          e[i++] = t;
        }, f = 1; f <= n; ++f) if (t[f] == o && f != n) ++a;else {
        if (!o && a > 2) {
          for (; a > 138; a -= 138) s(32754);
          a > 2 && (s(a > 10 ? a - 11 << 5 | 28690 : a - 3 << 5 | 12305), a = 0);
        } else if (a > 3) {
          for (s(o), --a; a > 6; a -= 6) s(8304);
          a > 2 && (s(a - 3 << 5 | 8208), a = 0);
        }
        for (; a--;) s(o);
        a = 1, o = t[f];
      }
      return [e.subarray(0, i), n];
    },
    E = function (t, n) {
      for (var r = 0, e = 0; e < n.length; ++e) r += t[e] * n[e];
      return r;
    },
    G = function (t, n, r) {
      var e = r.length,
        i = D(n + 2);
      t[i] = 255 & e, t[i + 1] = e >>> 8, t[i + 2] = 255 ^ t[i], t[i + 3] = 255 ^ t[i + 1];
      for (var o = 0; o < e; ++o) t[i + o + 4] = r[o];
      return 8 * (i + 4 + e);
    },
    P = function (t, n, e, s, f, u, h, c, l, p, v) {
      O(n, v++, e), ++f[256];
      for (var d = Z(f, 15), g = d[0], x = d[1], k = Z(u, 15), M = k[0], A = k[1], S = F(g), D = S[0], C = S[1], U = F(M), I = U[0], P = U[1], j = new r(19), _ = 0; _ < D.length; ++_) j[31 & D[_]]++;
      for (_ = 0; _ < I.length; ++_) j[31 & I[_]]++;
      for (var q = Z(j, 7), H = q[0], Y = q[1], B = 19; B > 4 && !H[a[B - 1]]; --B);
      var J,
        K,
        L,
        N,
        Q = p + 5 << 3,
        R = E(f, y) + E(u, m) + h,
        V = E(f, g) + E(u, M) + h + 14 + 3 * B + E(j, H) + (2 * j[16] + 3 * j[17] + 7 * j[18]);
      if (Q <= R && Q <= V) return G(n, v, t.subarray(l, l + p));
      if (O(n, v, 1 + (V < R)), v += 2, V < R) {
        J = w(g, x, 0), K = g, L = w(M, A, 0), N = M;
        var W = w(H, Y, 0);
        for (O(n, v, C - 257), O(n, v + 5, P - 1), O(n, v + 10, B - 4), v += 14, _ = 0; _ < B; ++_) O(n, v + 3 * _, H[a[_]]);
        v += 3 * B;
        for (var X = [D, I], $ = 0; $ < 2; ++$) {
          var tt = X[$];
          for (_ = 0; _ < tt.length; ++_) O(n, v, W[nt = 31 & tt[_]]), v += H[nt], nt > 15 && (O(n, v, tt[_] >>> 5 & 127), v += tt[_] >>> 12);
        }
      } else J = b, K = y, L = z, N = m;
      for (_ = 0; _ < c; ++_) if (s[_] > 255) {
        var nt;
        T(n, v, J[257 + (nt = s[_] >>> 18 & 31)]), v += K[nt + 257], nt > 7 && (O(n, v, s[_] >>> 23 & 31), v += i[nt]);
        var rt = 31 & s[_];
        T(n, v, L[rt]), v += N[rt], rt > 3 && (T(n, v, s[_] >>> 5 & 8191), v += o[rt]);
      } else T(n, v, J[s[_]]), v += K[s[_]];
      return T(n, v, J[256]), v + K[256];
    },
    j = new e([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
    _ = new n(0),
    q = function (t, a, s, f, u, c) {
      var l = t.length,
        v = new n(f + l + 5 * (1 + Math.ceil(l / 7e3)) + u),
        d = v.subarray(f, v.length - u),
        g = 0;
      if (!a || l < 8) for (var w = 0; w <= l; w += 65535) {
        var y = w + 65535;
        y < l ? g = G(d, g, t.subarray(w, y)) : (d[w] = c, g = G(d, g, t.subarray(w, l)));
      } else {
        for (var m = j[a - 1], b = m >>> 13, x = 8191 & m, z = (1 << s) - 1, k = new r(32768), M = new r(z + 1), A = Math.ceil(s / 3), S = 2 * A, U = function (n) {
            return (t[n] ^ t[n + 1] << A ^ t[n + 2] << S) & z;
          }, O = new e(25e3), T = new r(288), Z = new r(32), I = 0, F = 0, E = (w = 0, 0), q = 0, H = 0; w < l; ++w) {
          var Y = U(w),
            B = 32767 & w,
            J = M[Y];
          if (k[B] = J, M[Y] = B, q <= w) {
            var K = l - w;
            if ((I > 7e3 || E > 24576) && K > 423) {
              g = P(t, d, 0, O, T, Z, F, E, H, w - H, g), E = I = F = 0, H = w;
              for (var L = 0; L < 286; ++L) T[L] = 0;
              for (L = 0; L < 30; ++L) Z[L] = 0;
            }
            var N = 2,
              Q = 0,
              R = x,
              V = B - J & 32767;
            if (K > 2 && Y == U(w - V)) for (var W = Math.min(b, K) - 1, X = Math.min(32767, w), $ = Math.min(258, K); V <= X && --R && B != J;) {
              if (t[w + N] == t[w + N - V]) {
                for (var tt = 0; tt < $ && t[w + tt] == t[w + tt - V]; ++tt);
                if (tt > N) {
                  if (N = tt, Q = V, tt > W) break;
                  var nt = Math.min(V, tt - 2),
                    rt = 0;
                  for (L = 0; L < nt; ++L) {
                    var et = w - V + L + 32768 & 32767,
                      it = et - k[et] + 32768 & 32767;
                    it > rt && (rt = it, J = et);
                  }
                }
              }
              V += (B = J) - (J = k[B]) + 32768 & 32767;
            }
            if (Q) {
              O[E++] = 268435456 | h[N] << 18 | p[Q];
              var ot = 31 & h[N],
                at = 31 & p[Q];
              F += i[ot] + o[at], ++T[257 + ot], ++Z[at], q = w + N, ++I;
            } else O[E++] = t[w], ++T[t[w]];
          }
        }
        g = P(t, d, c, O, T, Z, F, E, H, w - H, g), !c && 7 & g && (g = G(d, g + 1, _));
      }
      return C(v, 0, f + D(g) + u);
    },
    H = function () {
      for (var t = new e(256), n = 0; n < 256; ++n) {
        for (var r = n, i = 9; --i;) r = (1 & r && 3988292384) ^ r >>> 1;
        t[n] = r;
      }
      return t;
    }(),
    Y = function () {
      var t = -1;
      return {
        p: function (n) {
          for (var r = t, e = 0; e < n.length; ++e) r = H[255 & r ^ n[e]] ^ r >>> 8;
          t = r;
        },
        d: function () {
          return ~t;
        }
      };
    },
    B = function () {
      var t = 1,
        n = 0;
      return {
        p: function (r) {
          for (var e = t, i = n, o = r.length, a = 0; a != o;) {
            for (var s = Math.min(a + 2655, o); a < s; ++a) i += e += r[a];
            e = (65535 & e) + 15 * (e >> 16), i = (65535 & i) + 15 * (i >> 16);
          }
          t = e, n = i;
        },
        d: function () {
          return ((t %= 65521) >>> 8 << 16 | (255 & (n %= 65521)) << 8 | n >>> 8) + 2 * ((255 & t) << 23);
        }
      };
    },
    J = function (t, n, r, e, i) {
      return q(t, null == n.level ? 6 : n.level, null == n.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(t.length)))) : 12 + n.mem, r, e, !i);
    },
    K = function (t, n) {
      var r = {};
      for (var e in t) r[e] = t[e];
      for (var e in n) r[e] = n[e];
      return r;
    },
    L = function (t, n, r) {
      for (var e = t(), i = "" + t, o = i.slice(i.indexOf("[") + 1, i.lastIndexOf("]")).replace(/ /g, "").split(","), a = 0; a < e.length; ++a) {
        var s = e[a],
          f = o[a];
        if ("function" == typeof s) {
          n += ";" + f + "=";
          var u = "" + s;
          if (s.prototype) {
            if (-1 != u.indexOf("[native code]")) {
              var h = u.indexOf(" ", 8) + 1;
              n += u.slice(h, u.indexOf("(", h));
            } else for (var c in n += u, s.prototype) n += ";" + f + ".prototype." + c + "=" + s.prototype[c];
          } else n += u;
        } else r[f] = s;
      }
      return [n, r];
    },
    N = [],
    Q = function (t) {
      var i = [];
      for (var o in t) (t[o] instanceof n || t[o] instanceof r || t[o] instanceof e) && i.push((t[o] = new t[o].constructor(t[o])).buffer);
      return i;
    },
    R = function (n, r, e, i) {
      var o;
      if (!N[e]) {
        for (var a = "", s = {}, f = n.length - 1, u = 0; u < f; ++u) a = (o = L(n[u], a, s))[0], s = o[1];
        N[e] = L(n[f], a, s);
      }
      var h = K({}, N[e][1]);
      return t.default(N[e][0] + ";onmessage=function(e){for(var k in e.data)self[k]=e.data[k];onmessage=" + r + "}", e, h, Q(h), i);
    },
    V = function () {
      return [n, r, e, i, o, a, u, l, x, k, v, w, M, A, S, D, C, U, At, rt, et];
    },
    W = function () {
      return [n, r, e, i, o, a, h, p, b, y, z, m, v, j, _, w, O, T, Z, I, F, E, G, P, D, C, q, J, xt, rt];
    },
    X = function () {
      return [ct, vt, ht, Y, H];
    },
    $ = function () {
      return [lt, pt];
    },
    tt = function () {
      return [dt, ht, B];
    },
    nt = function () {
      return [gt];
    },
    rt = function (t) {
      return postMessage(t, [t.buffer]);
    },
    et = function (t) {
      return t && t.size && new n(t.size);
    },
    it = function (t, n, r, e, i, o) {
      var a = R(r, e, i, function (t, n) {
        a.terminate(), o(t, n);
      });
      return a.postMessage([t, n], n.consume ? [t.buffer] : []), function () {
        a.terminate();
      };
    },
    ot = function (t) {
      return t.ondata = function (t, n) {
        return postMessage([t, n], [t.buffer]);
      }, function (n) {
        return t.push(n.data[0], n.data[1]);
      };
    },
    at = function (t, n, r, e, i) {
      var o,
        a = R(t, e, i, function (t, r) {
          t ? (a.terminate(), n.ondata.call(n, t)) : (r[1] && a.terminate(), n.ondata.call(n, t, r[0], r[1]));
        });
      a.postMessage(r), n.push = function (t, r) {
        if (o) throw "stream finished";
        if (!n.ondata) throw "no stream handler";
        a.postMessage([t, o = r], [t.buffer]);
      }, n.terminate = function () {
        a.terminate();
      };
    },
    st = function (t, n) {
      return t[n] | t[n + 1] << 8;
    },
    ft = function (t, n) {
      return (t[n] | t[n + 1] << 8 | t[n + 2] << 16) + 2 * (t[n + 3] << 23);
    },
    ut = function (t, n) {
      return ft(t, n) | 4294967296 * ft(t, n);
    },
    ht = function (t, n, r) {
      for (; r; ++n) t[n] = r, r >>>= 8;
    },
    ct = function (t, n) {
      var r = n.filename;
      if (t[0] = 31, t[1] = 139, t[2] = 8, t[8] = n.level < 2 ? 4 : 9 == n.level ? 2 : 0, t[9] = 3, 0 != n.mtime && ht(t, 4, Math.floor(new Date(n.mtime || Date.now()) / 1e3)), r) {
        t[3] = 8;
        for (var e = 0; e <= r.length; ++e) t[e + 10] = r.charCodeAt(e);
      }
    },
    lt = function (t) {
      if (31 != t[0] || 139 != t[1] || 8 != t[2]) throw "invalid gzip data";
      var n = t[3],
        r = 10;
      4 & n && (r += t[10] | 2 + (t[11] << 8));
      for (var e = (n >> 3 & 1) + (n >> 4 & 1); e > 0; e -= !t[r++]);
      return r + (2 & n);
    },
    pt = function (t) {
      var n = t.length;
      return (t[n - 4] | t[n - 3] << 8 | t[n - 2] << 16) + 2 * (t[n - 1] << 23);
    },
    vt = function (t) {
      return 10 + (t.filename && t.filename.length + 1 || 0);
    },
    dt = function (t, n) {
      var r = n.level,
        e = 0 == r ? 0 : r < 6 ? 1 : 9 == r ? 3 : 2;
      t[0] = 120, t[1] = e << 6 | (e ? 32 - 2 * e : 1);
    },
    gt = function (t) {
      if (8 != (15 & t[0]) || t[0] >>> 4 > 7 || (t[0] << 8 | t[1]) % 31) throw "invalid zlib data";
      if (32 & t[1]) throw "invalid zlib data: preset dictionaries not supported";
    };
  function wt(t, n) {
    return n || "function" != typeof t || (n = t, t = {}), this.ondata = n, t;
  }
  var yt = function () {
    function t(t, n) {
      n || "function" != typeof t || (n = t, t = {}), this.ondata = n, this.o = t || {};
    }
    return t.prototype.p = function (t, n) {
      this.ondata(J(t, this.o, 0, 0, !n), n);
    }, t.prototype.push = function (t, n) {
      if (this.d) throw "stream finished";
      if (!this.ondata) throw "no stream handler";
      this.d = n, this.p(t, n || !1);
    }, t;
  }();
  _e.Deflate = yt;
  var mt = function () {
    return function (t, n) {
      at([W, function () {
        return [ot, yt];
      }], this, wt.call(this, t, n), function (t) {
        var n = new yt(t.data);
        onmessage = ot(n);
      }, 6);
    };
  }();
  function bt(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return it(t, n, [W], function (t) {
      return rt(xt(t.data[0], t.data[1]));
    }, 0, r);
  }
  function xt(t, n) {
    return J(t, n || {}, 0, 0);
  }
  _e.AsyncDeflate = mt, _e.deflate = bt, _e.deflateSync = xt;
  var zt = function () {
    function t(t) {
      this.s = {}, this.p = new n(0), this.ondata = t;
    }
    return t.prototype.e = function (t) {
      if (this.d) throw "stream finished";
      if (!this.ondata) throw "no stream handler";
      var r = this.p.length,
        e = new n(r + t.length);
      e.set(this.p), e.set(t, r), this.p = e;
    }, t.prototype.c = function (t) {
      this.d = this.s.i = t || !1;
      var n = this.s.b,
        r = U(this.p, this.o, this.s);
      this.ondata(C(r, n, this.s.b), this.d), this.o = C(r, this.s.b - 32768), this.s.b = this.o.length, this.p = C(this.p, this.s.p / 8 | 0), this.s.p &= 7;
    }, t.prototype.push = function (t, n) {
      this.e(t), this.c(n);
    }, t;
  }();
  _e.Inflate = zt;
  var kt = function () {
    return function (t) {
      this.ondata = t, at([V, function () {
        return [ot, zt];
      }], this, 0, function () {
        var t = new zt();
        onmessage = ot(t);
      }, 7);
    };
  }();
  function Mt(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return it(t, n, [V], function (t) {
      return rt(At(t.data[0], et(t.data[1])));
    }, 1, r);
  }
  function At(t, n) {
    return U(t, n);
  }
  _e.AsyncInflate = kt, _e.inflate = Mt, _e.inflateSync = At;
  var St = function () {
    function t(t, n) {
      this.c = Y(), this.l = 0, this.v = 1, yt.call(this, t, n);
    }
    return t.prototype.push = function (t, n) {
      yt.prototype.push.call(this, t, n);
    }, t.prototype.p = function (t, n) {
      this.c.p(t), this.l += t.length;
      var r = J(t, this.o, this.v && vt(this.o), n && 8, !n);
      this.v && (ct(r, this.o), this.v = 0), n && (ht(r, r.length - 8, this.c.d()), ht(r, r.length - 4, this.l)), this.ondata(r, n);
    }, t;
  }();
  _e.Gzip = St, _e.Compress = St;
  var Dt = function () {
    return function (t, n) {
      at([W, X, function () {
        return [ot, yt, St];
      }], this, wt.call(this, t, n), function (t) {
        var n = new St(t.data);
        onmessage = ot(n);
      }, 8);
    };
  }();
  function Ct(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return it(t, n, [W, X, function () {
      return [Ut];
    }], function (t) {
      return rt(Ut(t.data[0], t.data[1]));
    }, 2, r);
  }
  function Ut(t, n) {
    n || (n = {});
    var r = Y(),
      e = t.length;
    r.p(t);
    var i = J(t, n, vt(n), 8),
      o = i.length;
    return ct(i, n), ht(i, o - 8, r.d()), ht(i, o - 4, e), i;
  }
  _e.AsyncGzip = Dt, _e.AsyncCompress = Dt, _e.gzip = Ct, _e.compress = Ct, _e.gzipSync = Ut, _e.compressSync = Ut;
  var Ot = function () {
    function t(t) {
      this.v = 1, zt.call(this, t);
    }
    return t.prototype.push = function (t, n) {
      if (zt.prototype.e.call(this, t), this.v) {
        var r = this.p.length > 3 ? lt(this.p) : 4;
        if (r >= this.p.length && !n) return;
        this.p = this.p.subarray(r), this.v = 0;
      }
      if (n) {
        if (this.p.length < 8) throw "invalid gzip stream";
        this.p = this.p.subarray(0, -8);
      }
      zt.prototype.c.call(this, n);
    }, t;
  }();
  _e.Gunzip = Ot;
  var Tt = function () {
    return function (t) {
      this.ondata = t, at([V, $, function () {
        return [ot, zt, Ot];
      }], this, 0, function () {
        var t = new Ot();
        onmessage = ot(t);
      }, 9);
    };
  }();
  function Zt(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return it(t, n, [V, $, function () {
      return [It];
    }], function (t) {
      return rt(It(t.data[0]));
    }, 3, r);
  }
  function It(t, r) {
    return U(t.subarray(lt(t), -8), r || new n(pt(t)));
  }
  _e.AsyncGunzip = Tt, _e.gunzip = Zt, _e.gunzipSync = It;
  var Ft = function () {
    function t(t, n) {
      this.c = B(), this.v = 1, yt.call(this, t, n);
    }
    return t.prototype.push = function (t, n) {
      yt.prototype.push.call(this, t, n);
    }, t.prototype.p = function (t, n) {
      this.c.p(t);
      var r = J(t, this.o, this.v && 2, n && 4, !n);
      this.v && (dt(r, this.o), this.v = 0), n && ht(r, r.length - 4, this.c.d()), this.ondata(r, n);
    }, t;
  }();
  _e.Zlib = Ft;
  var Et = function () {
    return function (t, n) {
      at([W, tt, function () {
        return [ot, yt, Ft];
      }], this, wt.call(this, t, n), function (t) {
        var n = new Ft(t.data);
        onmessage = ot(n);
      }, 10);
    };
  }();
  function Gt(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return it(t, n, [W, tt, function () {
      return [Pt];
    }], function (t) {
      return rt(Pt(t.data[0], t.data[1]));
    }, 4, r);
  }
  function Pt(t, n) {
    n || (n = {});
    var r = B();
    r.p(t);
    var e = J(t, n, 2, 4);
    return dt(e, n), ht(e, e.length - 4, r.d()), e;
  }
  _e.AsyncZlib = Et, _e.zlib = Gt, _e.zlibSync = Pt;
  var jt = function () {
    function t(t) {
      this.v = 1, zt.call(this, t);
    }
    return t.prototype.push = function (t, n) {
      if (zt.prototype.e.call(this, t), this.v) {
        if (this.p.length < 2 && !n) return;
        this.p = this.p.subarray(2), this.v = 0;
      }
      if (n) {
        if (this.p.length < 4) throw "invalid zlib stream";
        this.p = this.p.subarray(0, -4);
      }
      zt.prototype.c.call(this, n);
    }, t;
  }();
  _e.Unzlib = jt;
  var _t = function () {
    return function (t) {
      this.ondata = t, at([V, nt, function () {
        return [ot, zt, jt];
      }], this, 0, function () {
        var t = new jt();
        onmessage = ot(t);
      }, 11);
    };
  }();
  function qt(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return it(t, n, [V, nt, function () {
      return [Ht];
    }], function (t) {
      return rt(Ht(t.data[0], et(t.data[1])));
    }, 5, r);
  }
  function Ht(t, n) {
    return U((gt(t), t.subarray(2, -4)), n);
  }
  _e.AsyncUnzlib = _t, _e.unzlib = qt, _e.unzlibSync = Ht;
  var Yt = function () {
    function t(t) {
      this.G = Ot, this.I = zt, this.Z = jt, this.ondata = t;
    }
    return t.prototype.push = function (t, r) {
      if (!this.ondata) throw "no stream handler";
      if (this.s) this.s.push(t, r);else {
        if (this.p && this.p.length) {
          var e = new n(this.p.length + t.length);
          e.set(this.p), e.set(t, this.p.length);
        } else this.p = t;
        if (this.p.length > 2) {
          var i = this,
            o = function () {
              i.ondata.apply(i, arguments);
            };
          this.s = 31 == this.p[0] && 139 == this.p[1] && 8 == this.p[2] ? new this.G(o) : 8 != (15 & this.p[0]) || this.p[0] >> 4 > 7 || (this.p[0] << 8 | this.p[1]) % 31 ? new this.I(o) : new this.Z(o), this.s.push(this.p, r), this.p = null;
        }
      }
    }, t;
  }();
  _e.Decompress = Yt;
  var Bt = function () {
    function t(t) {
      this.G = Tt, this.I = kt, this.Z = _t, this.ondata = t;
    }
    return t.prototype.push = function (t, n) {
      Yt.prototype.push.call(this, t, n);
    }, t;
  }();
  function Jt(t, n, r) {
    if (r || (r = n, n = {}), "function" != typeof r) throw "no callback";
    return 31 == t[0] && 139 == t[1] && 8 == t[2] ? Zt(t, n, r) : 8 != (15 & t[0]) || t[0] >> 4 > 7 || (t[0] << 8 | t[1]) % 31 ? Mt(t, n, r) : qt(t, n, r);
  }
  function Kt(t, n) {
    return 31 == t[0] && 139 == t[1] && 8 == t[2] ? It(t, n) : 8 != (15 & t[0]) || t[0] >> 4 > 7 || (t[0] << 8 | t[1]) % 31 ? At(t, n) : Ht(t, n);
  }
  _e.AsyncDecompress = Bt, _e.decompress = Jt, _e.decompressSync = Kt;
  var Lt = function (t, r, e, i) {
      for (var o in t) {
        var a = t[o],
          s = r + o;
        a instanceof n ? e[s] = [a, i] : Array.isArray(a) ? e[s] = [a[0], K(i, a[1])] : Lt(a, s + "/", e, i);
      }
    },
    Nt = "undefined" != typeof TextEncoder && new TextEncoder(),
    Qt = "undefined" != typeof TextDecoder && new TextDecoder(),
    Rt = 0;
  try {
    Qt.decode(_, {
      stream: !0
    }), Rt = 1;
  } catch (t) {}
  var Vt = function (t) {
      for (var n = "", r = 0;;) {
        var e = t[r++],
          i = (e > 127) + (e > 223) + (e > 239);
        if (r + i > t.length) return [n, C(t, r - 1)];
        i ? 3 == i ? (e = ((15 & e) << 18 | (63 & t[r++]) << 12 | (63 & t[r++]) << 6 | 63 & t[r++]) - 65536, n += String.fromCharCode(55296 | e >> 10, 56320 | 1023 & e)) : n += String.fromCharCode(1 & i ? (31 & e) << 6 | 63 & t[r++] : (15 & e) << 12 | (63 & t[r++]) << 6 | 63 & t[r++]) : n += String.fromCharCode(e);
      }
    },
    Wt = function () {
      function t(t) {
        this.ondata = t, Rt ? this.t = new TextDecoder() : this.p = _;
      }
      return t.prototype.push = function (t, r) {
        if (!this.ondata) throw "no callback";
        if (r || (r = !1), this.t) return this.ondata(this.t.decode(t, {
          stream: !r
        }), r);
        var e = new n(this.p.length + t.length);
        e.set(this.p), e.set(t, this.p.length);
        var i = Vt(e),
          o = i[0],
          a = i[1];
        if (r && a.length) throw "invalid utf-8 data";
        this.p = a, this.ondata(o, r);
      }, t;
    }();
  _e.DecodeUTF8 = Wt;
  var Xt = function () {
    function t(t) {
      this.ondata = t;
    }
    return t.prototype.push = function (t, n) {
      if (!this.ondata) throw "no callback";
      this.ondata($t(t), n || !1);
    }, t;
  }();
  function $t(t, r) {
    if (r) {
      for (var e = new n(t.length), i = 0; i < t.length; ++i) e[i] = t.charCodeAt(i);
      return e;
    }
    if (Nt) return Nt.encode(t);
    var o = t.length,
      a = new n(t.length + (t.length >> 1)),
      s = 0,
      f = function (t) {
        a[s++] = t;
      };
    for (i = 0; i < o; ++i) {
      if (s + 5 > a.length) {
        var u = new n(s + 8 + (o - i << 1));
        u.set(a), a = u;
      }
      var h = t.charCodeAt(i);
      h < 128 || r ? f(h) : h < 2048 ? (f(192 | h >>> 6), f(128 | 63 & h)) : h > 55295 && h < 57344 ? (f(240 | (h = 65536 + (1047552 & h) | 1023 & t.charCodeAt(++i)) >>> 18), f(128 | h >>> 12 & 63), f(128 | h >>> 6 & 63), f(128 | 63 & h)) : (f(224 | h >>> 12), f(128 | h >>> 6 & 63), f(128 | 63 & h));
    }
    return C(a, 0, s);
  }
  function tn(t, n) {
    if (n) {
      for (var r = "", e = 0; e < t.length; e += 16384) r += String.fromCharCode.apply(null, t.subarray(e, e + 16384));
      return r;
    }
    if (Qt) return Qt.decode(t);
    var i = Vt(t);
    if (i[1].length) throw "invalid utf-8 data";
    return i[0];
  }
  _e.EncodeUTF8 = Xt, _e.strToU8 = $t, _e.strFromU8 = tn;
  var nn = function (t) {
      return 1 == t ? 3 : t < 6 ? 2 : 9 == t ? 1 : 0;
    },
    rn = function (t, n) {
      return n + 30 + st(t, n + 26) + st(t, n + 28);
    },
    en = function (t, n, r) {
      var e = st(t, n + 28),
        i = tn(t.subarray(n + 46, n + 46 + e), !(2048 & st(t, n + 8))),
        o = n + 46 + e,
        a = ft(t, n + 20),
        s = r && 4294967295 == a ? on(t, o) : [a, ft(t, n + 24), ft(t, n + 42)],
        f = s[0],
        u = s[1],
        h = s[2];
      return [st(t, n + 10), f, u, i, o + st(t, n + 30) + st(t, n + 32), h];
    },
    on = function (t, n) {
      for (; 1 != st(t, n); n += 4 + st(t, n + 2));
      return [ut(t, n + 12), ut(t, n + 4), ut(t, n + 20)];
    },
    an = function (t) {
      var n = 0;
      if (t) for (var r in t) {
        var e = t[r].length;
        if (e > 65535) throw "extra field too long";
        n += e + 4;
      }
      return n;
    },
    sn = function (t, n, r, e, i, o, a, s) {
      var f = e.length,
        u = r.extra,
        h = s && s.length,
        c = an(u);
      ht(t, n, null != a ? 33639248 : 67324752), n += 4, null != a && (t[n++] = 20, t[n++] = r.os), t[n] = 20, n += 2, t[n++] = r.flag << 1 | (null == o && 8), t[n++] = i && 8, t[n++] = 255 & r.compression, t[n++] = r.compression >> 8;
      var l = new Date(null == r.mtime ? Date.now() : r.mtime),
        p = l.getFullYear() - 1980;
      if (p < 0 || p > 119) throw "date not in range 1980-2099";
      if (ht(t, n, 2 * (p << 24) | l.getMonth() + 1 << 21 | l.getDate() << 16 | l.getHours() << 11 | l.getMinutes() << 5 | l.getSeconds() >>> 1), n += 4, null != o && (ht(t, n, r.crc), ht(t, n + 4, o), ht(t, n + 8, r.size)), ht(t, n + 12, f), ht(t, n + 14, c), n += 16, null != a && (ht(t, n, h), ht(t, n + 6, r.attrs), ht(t, n + 10, a), n += 14), t.set(e, n), n += f, c) for (var v in u) {
        var d = u[v],
          g = d.length;
        ht(t, n, +v), ht(t, n + 2, g), t.set(d, n + 4), n += 4 + g;
      }
      return h && (t.set(s, n), n += h), n;
    },
    fn = function (t, n, r, e, i) {
      ht(t, n, 101010256), ht(t, n + 8, r), ht(t, n + 10, r), ht(t, n + 12, e), ht(t, n + 16, i);
    },
    un = function () {
      function t(t) {
        this.filename = t, this.c = Y(), this.size = 0, this.compression = 0;
      }
      return t.prototype.process = function (t, n) {
        this.ondata(null, t, n);
      }, t.prototype.push = function (t, n) {
        if (!this.ondata) throw "no callback - add to ZIP archive before pushing";
        this.c.p(t), this.size += t.length, n && (this.crc = this.c.d()), this.process(t, n || !1);
      }, t;
    }();
  _e.ZipPassThrough = un;
  var hn = function () {
    function t(t, n) {
      var r = this;
      n || (n = {}), un.call(this, t), this.d = new yt(n, function (t, n) {
        r.ondata(null, t, n);
      }), this.compression = 8, this.flag = nn(n.level);
    }
    return t.prototype.process = function (t, n) {
      try {
        this.d.push(t, n);
      } catch (t) {
        this.ondata(t, null, n);
      }
    }, t.prototype.push = function (t, n) {
      un.prototype.push.call(this, t, n);
    }, t;
  }();
  _e.ZipDeflate = hn;
  var cn = function () {
    function t(t, n) {
      var r = this;
      n || (n = {}), un.call(this, t), this.d = new mt(n, function (t, n, e) {
        r.ondata(t, n, e);
      }), this.compression = 8, this.flag = nn(n.level), this.terminate = this.d.terminate;
    }
    return t.prototype.process = function (t, n) {
      this.d.push(t, n);
    }, t.prototype.push = function (t, n) {
      un.prototype.push.call(this, t, n);
    }, t;
  }();
  _e.AsyncZipDeflate = cn;
  var ln = function () {
    function t(t) {
      this.ondata = t, this.u = [], this.d = 1;
    }
    return t.prototype.add = function (t) {
      var r = this;
      if (2 & this.d) throw "stream finished";
      var e = $t(t.filename),
        i = e.length,
        o = t.comment,
        a = o && $t(o),
        s = i != t.filename.length || a && o.length != a.length,
        f = i + an(t.extra) + 30;
      if (i > 65535) throw "filename too long";
      var u = new n(f);
      sn(u, 0, t, e, s);
      var h = [u],
        c = function () {
          for (var t = 0, n = h; t < n.length; t++) r.ondata(null, n[t], !1);
          h = [];
        },
        l = this.d;
      this.d = 0;
      var p = this.u.length,
        v = K(t, {
          f: e,
          u: s,
          o: a,
          t: function () {
            t.terminate && t.terminate();
          },
          r: function () {
            if (c(), l) {
              var t = r.u[p + 1];
              t ? t.r() : r.d = 1;
            }
            l = 1;
          }
        }),
        d = 0;
      t.ondata = function (e, i, o) {
        if (e) r.ondata(e, i, o), r.terminate();else if (d += i.length, h.push(i), o) {
          var a = new n(16);
          ht(a, 0, 134695760), ht(a, 4, t.crc), ht(a, 8, d), ht(a, 12, t.size), h.push(a), v.c = d, v.b = f + d + 16, v.crc = t.crc, v.size = t.size, l && v.r(), l = 1;
        } else l && c();
      }, this.u.push(v);
    }, t.prototype.end = function () {
      var t = this;
      if (2 & this.d) {
        if (1 & this.d) throw "stream finishing";
        throw "stream finished";
      }
      this.d ? this.e() : this.u.push({
        r: function () {
          1 & t.d && (t.u.splice(-1, 1), t.e());
        },
        t: function () {}
      }), this.d = 3;
    }, t.prototype.e = function () {
      for (var t = 0, r = 0, e = 0, i = 0, o = this.u; i < o.length; i++) e += 46 + (u = o[i]).f.length + an(u.extra) + (u.o ? u.o.length : 0);
      for (var a = new n(e + 22), s = 0, f = this.u; s < f.length; s++) {
        var u;
        sn(a, t, u = f[s], u.f, u.u, u.c, r, u.o), t += 46 + u.f.length + an(u.extra) + (u.o ? u.o.length : 0), r += u.b;
      }
      fn(a, t, this.u.length, e, r), this.ondata(null, a, !0), this.d = 2;
    }, t.prototype.terminate = function () {
      for (var t = 0, n = this.u; t < n.length; t++) n[t].t();
      this.d = 2;
    }, t;
  }();
  function pn(t, r, e) {
    if (e || (e = r, r = {}), "function" != typeof e) throw "no callback";
    var i = {};
    Lt(t, "", i, r);
    var o = Object.keys(i),
      a = o.length,
      s = 0,
      f = 0,
      u = a,
      h = Array(a),
      c = [],
      l = function () {
        for (var t = 0; t < c.length; ++t) c[t]();
      },
      p = function () {
        var t = new n(f + 22),
          r = s,
          i = f - s;
        f = 0;
        for (var o = 0; o < u; ++o) {
          var a = h[o];
          try {
            var c = a.c.length;
            sn(t, f, a, a.f, a.u, c);
            var l = 30 + a.f.length + an(a.extra),
              p = f + l;
            t.set(a.c, p), sn(t, s, a, a.f, a.u, c, f, a.m), s += 16 + l + (a.m ? a.m.length : 0), f = p + c;
          } catch (t) {
            return e(t, null);
          }
        }
        fn(t, s, h.length, i, r), e(null, t);
      };
    a || p();
    for (var v = function (t) {
        var n = o[t],
          r = i[n],
          u = r[0],
          v = r[1],
          d = Y(),
          g = u.length;
        d.p(u);
        var w = $t(n),
          y = w.length,
          m = v.comment,
          b = m && $t(m),
          x = b && b.length,
          z = an(v.extra),
          k = 0 == v.level ? 0 : 8,
          M = function (r, i) {
            if (r) l(), e(r, null);else {
              var o = i.length;
              h[t] = K(v, {
                size: g,
                crc: d.d(),
                c: i,
                f: w,
                m: b,
                u: y != n.length || b && m.length != x,
                compression: k
              }), s += 30 + y + z + o, f += 76 + 2 * (y + z) + (x || 0) + o, --a || p();
            }
          };
        if (y > 65535 && M("filename too long", null), k) {
          if (g < 16e4) try {
            M(null, xt(u, v));
          } catch (t) {
            M(t, null);
          } else c.push(bt(u, v, M));
        } else M(null, u);
      }, d = 0; d < u; ++d) v(d);
    return l;
  }
  function vn(t, r) {
    r || (r = {});
    var e = {},
      i = [];
    Lt(t, "", e, r);
    var o = 0,
      a = 0;
    for (var s in e) {
      var f = e[s],
        u = f[0],
        h = f[1],
        c = 0 == h.level ? 0 : 8,
        l = (M = $t(s)).length,
        p = h.comment,
        v = p && $t(p),
        d = v && v.length,
        g = an(h.extra);
      if (l > 65535) throw "filename too long";
      var w = c ? xt(u, h) : u,
        y = w.length,
        m = Y();
      m.p(u), i.push(K(h, {
        size: u.length,
        crc: m.d(),
        c: w,
        f: M,
        m: v,
        u: l != s.length || v && p.length != d,
        o: o,
        compression: c
      })), o += 30 + l + g + y, a += 76 + 2 * (l + g) + (d || 0) + y;
    }
    for (var b = new n(a + 22), x = o, z = a - o, k = 0; k < i.length; ++k) {
      var M;
      sn(b, (M = i[k]).o, M, M.f, M.u, M.c.length);
      var A = 30 + M.f.length + an(M.extra);
      b.set(M.c, M.o + A), sn(b, o, M, M.f, M.u, M.c.length, M.o, M.m), o += 16 + A + (M.m ? M.m.length : 0);
    }
    return fn(b, o, i.length, z, x), b;
  }
  _e.Zip = ln, _e.zip = pn, _e.zipSync = vn;
  var dn = function () {
    function t() {}
    return t.prototype.push = function (t, n) {
      this.ondata(null, t, n);
    }, t.compression = 0, t;
  }();
  _e.UnzipPassThrough = dn;
  var gn = function () {
    function t() {
      var t = this;
      this.i = new zt(function (n, r) {
        t.ondata(null, n, r);
      });
    }
    return t.prototype.push = function (t, n) {
      try {
        this.i.push(t, n);
      } catch (r) {
        this.ondata(r, t, n);
      }
    }, t.compression = 8, t;
  }();
  _e.UnzipInflate = gn;
  var wn = function () {
    function t(t, n) {
      var r = this;
      n < 32e4 ? this.i = new zt(function (t, n) {
        r.ondata(null, t, n);
      }) : (this.i = new kt(function (t, n, e) {
        r.ondata(t, n, e);
      }), this.terminate = this.i.terminate);
    }
    return t.prototype.push = function (t, n) {
      this.i.terminate && (t = C(t, 0)), this.i.push(t, n);
    }, t.compression = 8, t;
  }();
  _e.AsyncUnzipInflate = wn;
  var yn = function () {
    function t(t) {
      this.onfile = t, this.k = [], this.o = {
        0: dn
      }, this.p = _;
    }
    return t.prototype.push = function (t, r) {
      var e = this;
      if (!this.onfile) throw "no callback";
      if (this.c > 0) {
        var i = Math.min(this.c, t.length),
          o = t.subarray(0, i);
        if (this.c -= i, this.d ? this.d.push(o, !this.c) : this.k[0].push(o), (t = t.subarray(i)).length) return this.push(t, r);
      } else {
        var a = 0,
          s = 0,
          f = void 0,
          u = void 0;
        this.p.length ? t.length ? ((u = new n(this.p.length + t.length)).set(this.p), u.set(t, this.p.length)) : u = this.p : u = t;
        for (var h = u.length, c = this.c, l = c && this.d, p = function () {
            var t,
              n = ft(u, s);
            if (67324752 == n) {
              a = 1, f = s, v.d = null, v.c = 0;
              var r = st(u, s + 6),
                i = st(u, s + 8),
                o = 2048 & r,
                l = 8 & r,
                p = st(u, s + 26),
                d = st(u, s + 28);
              if (h > s + 30 + p + d) {
                var g = [];
                v.k.unshift(g), a = 2;
                var w = ft(u, s + 18),
                  y = ft(u, s + 22),
                  m = tn(u.subarray(s + 30, s += 30 + p), !o);
                4294967295 == w ? (t = l ? [-2] : on(u, s), w = t[0], y = t[1]) : l && (w = -1), s += d, v.c = w;
                var b = {
                  name: m,
                  compression: i,
                  start: function () {
                    if (!b.ondata) throw "no callback";
                    if (w) {
                      var t = e.o[i];
                      if (!t) throw "unknown compression type " + i;
                      var n = w < 0 ? new t(m) : new t(m, w, y);
                      n.ondata = function (t, n, r) {
                        b.ondata(t, n, r);
                      };
                      for (var r = 0, o = g; r < o.length; r++) n.push(o[r], !1);
                      e.k[0] == g ? e.d = n : n.push(_, !0);
                    } else b.ondata(null, _, !0);
                  },
                  terminate: function () {
                    e.k[0] == g && e.d.terminate && e.d.terminate();
                  }
                };
                w >= 0 && (b.size = w, b.originalSize = y), v.onfile(b);
              }
              return "break";
            }
            if (c) {
              if (134695760 == n) return f = s += 12 + (-2 == c && 8), a = 2, v.c = 0, "break";
              if (33639248 == n) return f = s -= 4, a = 2, v.c = 0, "break";
            }
          }, v = this; s < h - 4 && "break" !== p(); ++s);
        if (this.p = _, c < 0) {
          var d = u.subarray(0, a ? f - 12 - (-2 == c && 8) - (134695760 == ft(u, f - 16) && 4) : s);
          l ? l.push(d, !!a) : this.k[+(2 == a)].push(d);
        }
        if (2 & a) return this.push(u.subarray(s), r);
        this.p = u.subarray(s);
      }
      if (r && this.c) throw "invalid zip file";
    }, t.prototype.register = function (t) {
      this.o[t.compression] = t;
    }, t;
  }();
  function mn(t, r) {
    if ("function" != typeof r) throw "no callback";
    for (var e = [], i = function () {
        for (var t = 0; t < e.length; ++t) e[t]();
      }, o = {}, a = t.length - 22; 101010256 != ft(t, a); --a) if (!a || t.length - a > 65558) return void r("invalid zip file", null);
    var s = st(t, a + 8);
    s || r(null, {});
    var f = s,
      u = ft(t, a + 16),
      h = 4294967295 == u;
    if (h) {
      if (a = ft(t, a - 12), 101075792 != ft(t, a)) return void r("invalid zip file", null);
      f = s = ft(t, a + 32), u = ft(t, a + 48);
    }
    for (var c = function (a) {
        var f = en(t, u, h),
          c = f[0],
          l = f[1],
          p = f[2],
          v = f[3],
          d = f[4],
          g = rn(t, f[5]);
        u = d;
        var w = function (t, n) {
          t ? (i(), r(t, null)) : (o[v] = n, --s || r(null, o));
        };
        if (c) {
          if (8 == c) {
            var y = t.subarray(g, g + l);
            if (l < 32e4) try {
              w(null, At(y, new n(p)));
            } catch (t) {
              w(t, null);
            } else e.push(Mt(y, {
              size: p
            }, w));
          } else w("unknown compression type " + c, null);
        } else w(null, C(t, g, g + l));
      }, l = 0; l < f; ++l) c();
    return i;
  }
  function bn(t) {
    for (var r = {}, e = t.length - 22; 101010256 != ft(t, e); --e) if (!e || t.length - e > 65558) throw "invalid zip file";
    var i = st(t, e + 8);
    if (!i) return {};
    var o = ft(t, e + 16),
      a = 4294967295 == o;
    if (a) {
      if (e = ft(t, e - 12), 101075792 != ft(t, e)) throw "invalid zip file";
      i = ft(t, e + 32), o = ft(t, e + 48);
    }
    for (var s = 0; s < i; ++s) {
      var f = en(t, o, a),
        u = f[0],
        h = f[1],
        c = f[2],
        l = f[3],
        p = f[4],
        v = rn(t, f[5]);
      if (o = p, u) {
        if (8 != u) throw "unknown compression type " + u;
        r[l] = At(t.subarray(v, v + h), new n(c));
      } else r[l] = C(t, v, v + h);
    }
    return r;
  }
  _e.Unzip = yn, _e.unzip = mn, _e.unzipSync = bn;
  return _e;
});