/** @format */

import { Component, inject, Input, ViewChild } from '@angular/core';
import humanizeDuration from 'humanize-duration';
import { get, isEqual, isNil, join, map, round } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment from 'moment';
import { Equipment } from '../../../_classes/equipment/equipment.class';
import { ElevatorFloor, ElevatorLastDoorStates } from '../../../_classes/equipment/equipment.elevator.class';
import { Extremes } from '../../../_classes/extremes.class';
import { HumanizeFieldUpdateOrigin } from '../../../_classes/field-update-origin.class';
import { EquipmentCabinCallNameDatagridOptions } from '../../../_constants/equipment-cabin-call-name';
import { EquipmentDoorStateNameLabel } from '../../../_constants/equipment-door/equipment-door-state';
import { EquipmentSignatureStatusDatagridOptions } from '../../../_constants/equipment-signature-status';
import { EquipmentStatusDatagridOptions } from '../../../_constants/equipment/equipment-status';
import { getFloorName } from '../../../_helpers/equipment.helper';
import { CabinCallDataService } from '../../../_services/cabin-call-data.service';
import { DatagridComponent, IDatagridOptions } from '../../datagrid/datagrid.component';
import { IDatatableOptions } from '../../datagrid/datatable.class';

@Component({
    selector: 'app-cabin-call-datagrid',
    templateUrl: './cabin-call-datagrid.component.html',
    styleUrls: ['./cabin-call-datagrid.component.scss'],
    standalone: false
})
export class CabinCallDatagridComponent {
  @Input('equipment')
  set setEquipment(equipment: Equipment) {
    if (isEqual(equipment, this.equipment)) return;
    this.equipment = equipment;
    if (this.equipment) this.loadPage();
  }
  equipment: Equipment;

  public setExtremes(extremes: Extremes): void {
    if (isEqual(extremes, this.extremes)) return;
    this.extremes = extremes;
    this.loadPage();
  }
  extremes?: Extremes;

  options: IDatagridOptions = {
    pagination: { default: 0, options: [10, 20, 50, 100] },
    service: (query: IDatatableOptions) =>
      this.mouvementDataTriggerService.datatable(query, {
        equipmentId: this.equipment!._id,
        from: this.extremes?.start,
        to: this.extremes?.end,
      }),
    columns: [
      {
        property: 'equipmentSignatureStatus',
        label: $localize`Signature`,
        type: 'select',
        options: EquipmentSignatureStatusDatagridOptions,
        maxWidth: 300,
      },
      {
        property: 'equipmentStatus',
        label: $localize`Équipement`,
        type: 'select',
        options: EquipmentStatusDatagridOptions,
        maxWidth: 300,
      },
      {
        property: 'cabinCall',
        label: $localize`Appel cabine`,
        type: 'select',
        options: EquipmentCabinCallNameDatagridOptions,
        searchable: true,
        sortable: true,
      },
      {
        property: 'start',
        label: $localize`Date début`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 250,
      },
      {
        property: 'acknowledgedAt',
        label: $localize`Pris en compte à`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 300,
      },
      {
        property: 'end',
        label: $localize`Date de fin`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 250,
      },
      {
        property: 'duration',
        label: $localize`Durée`,
        displayWith: (record: any) => {
          const start = record.start ? new Date(record.start).valueOf() : null;
          const end = record.end ? new Date(record.end).valueOf() : null;
          if (!start || !end) return '-';
          return humanizeDuration(end - start, { language: 'fr', maxDecimalPoints: 0, largest: 1 });
        },
      },
      {
        property: 'lastMouvement.endDistance',
        label: $localize`Départ`,
        displayWith: (record: any) => {
          const floors = get(this.equipment, 'metadata.floors');
          if (!floors) return ``;
          const from = get(record, 'lastMouvement.endDistance');
          if (isNil(from)) return '-';
          return `<div>${getFloorName(floors, from) || '?'}</div><span light>${round(from / 100, 2)} m</span>`;
        },
        searchable: true,
        sortable: true,
      },
      {
        property: 'toDistance',
        label: $localize`Destination`,
        displayWith: (record: any) => {
          const floors: ElevatorFloor[] = get(this.equipment, 'metadata.floors', []);
          if (!floors) return ``;
          const to = get(record, 'toDistance');
          if (isNil(to)) return '-';
          return `<div>${getFloorName(floors, to) || '?'}</div><span light>${round(to / 100, 2)} m</span>`;
        },
        searchable: true,
        sortable: true,
      },
      {
        property: 'endDistance',
        label: $localize`Arrivée`,
        displayWith: (record: any) => {
          const floors = get(this.equipment, 'metadata.floors');
          if (!floors) return ``;
          const end = get(record, 'endDistance');
          if (isNil(end)) return '-';
          return `<div>${getFloorName(floors, end) || '?'}</div><span light>${round(end / 100, 2)} m</span>`;
        },
        searchable: true,
        sortable: true,
      },
      {
        property: 'distance',
        linkedProperties: ['endDistance', 'lastMouvement.endDistance'],
        label: $localize`Distance parcourue`,
        displayWith: (record: any) => {
          const from = get(record, 'lastMouvement.endDistance');
          if (isNil(from)) return '-';
          const to = get(record, 'endDistance');
          if (isNil(to)) return '-';
          return `${round(Math.abs(to - from) / 100, 2)} m`;
        },
      },
      {
        property: 'updateOrigin',
        label: $localize`Déclencheur`,
        maxWidth: 300,
        displayWith: (record: any) => HumanizeFieldUpdateOrigin(record.updateOrigin),
      },
      {
        property: 'lastDoorStates',
        label: $localize`Porte`,
        displayWith: (record: any) => {
          if (!record.lastDoorStates) return '-';
          const lastDoorStates = new ModelMapper(ElevatorLastDoorStates).map(record.lastDoorStates);
          return join(
            map(
              lastDoorStates.toArray(),
              (doorState) =>
                `<div>${EquipmentDoorStateNameLabel[doorState.doorName][doorState.state]} <span nowrap light>(${moment(doorState.date).format('L LT')})</span></div>`,
            ),
          );
        },
        maxWidth: 300,
      },
    ],
    sortedColumns: [{ column: 3, dir: 'desc' }],
  };
  @ViewChild('datagrid', { read: DatagridComponent, static: false })
  datagrid: DatagridComponent;

  private mouvementDataTriggerService = inject(CabinCallDataService);

  private loadPage() {
    if (this.datagrid) this.datagrid.loadPage();
  }
}
