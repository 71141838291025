/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'lodash-es';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'app-housing-chart',
    templateUrl: './housing-chart.component.html',
    styleUrls: ['./housing-chart.component.scss'],
    standalone: false
})
export class HousingChartComponent implements OnInit, OnDestroy {
  chartsOptions: any[];

  constructor() {}

  ngOnInit(): void {
    this.loadOptions();
  }

  ngOnDestroy(): void {}

  loadData(chart: ChartComponent, index: number) {
    const serie: any = { data: [] };
    switch (index) {
      case 0:
        serie.data.push({ y: 18 + Math.random() * 3, target: 19 });
        break;
      case 1:
        serie.data.push({ y: 55 + Math.random() * 3, target: 55 });
        break;
      case 2:
        serie.data.push({ y: 350 + Math.random() * 100, target: 400 });
        break;
    }
    chart.setSeries([serie]);
    chart.setLoading(false);
  }

  private loadOptions() {
    this.chartsOptions = map(
      [
        {
          label: $localize`Température`,
          suffix: $localize` °C`,
          min: -30,
          max: 50,
          plotBands: [
            {
              from: -9e9,
              to: 0,
              color: '#333',
            },
            {
              from: 0,
              to: 15,
              color: '#295974',
            },
            {
              from: 15,
              to: 18,
              color: '#47ABE3',
            },
            {
              from: 18,
              to: 21,
              color: '#33a357',
            },
            {
              from: 21,
              to: 25,
              color: '#ff7f00',
            },
            {
              from: 25,
              to: 9e9,
              color: '#cb242b',
            },
          ],
        },
        {
          label: $localize`Hygrométrie`,
          suffix: $localize` %`,
          min: 0,
          max: 100,
          target: 55,
          plotBands: [
            {
              from: 0,
              to: 30,
              color: '#295974',
            },
            {
              from: 30,
              to: 40,
              color: '#47ABE3',
            },
            {
              from: 40,
              to: 71,
              color: '#33a357',
            },
            {
              from: 71,
              to: 86,
              color: '#ff7f00',
            },
            {
              from: 86,
              to: 9e9,
              color: '#cb242b',
            },
          ],
        },
        {
          label: $localize`Qualité d'air`,
          suffix: $localize` ppm`,
          min: 0,
          max: 2000,
          target: 400,
          plotBands: [
            {
              from: 0,
              to: 401,
              color: '#33a357',
            },
            {
              from: 401,
              to: 601,
              color: '#79b752',
            },
            {
              from: 601,
              to: 801,
              color: '#edb730',
            },
            {
              from: 801,
              to: 1201,
              color: '#ff7f00',
            },
            {
              from: 1201,
              to: 2001,
              color: '#cb242b',
            },
            {
              from: 2001,
              to: 9e9,
              color: '#7e161a',
            },
          ],
        },
      ],
      (category) => ({
        chart: {
          inverted: true,
          type: 'bullet',
          height: '60px',
          padding: [0, 0, 0, 0],
          margin: [10, 0, 20, 100],
        },
        title: {
          text: null,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [`<span class="hc-cat-title">${category.label}</span>`],
        },
        yAxis: {
          gridLineWidth: 0,
          plotBands: category.plotBands,
          title: null,
          softMin: category.min,
          softMax: category.max,
          labels: {
            distance: 3,
            format: `{value}${category.suffix}`,
            autoRotation: undefined,
          },
        },
        plotOptions: {
          series: {
            pointPadding: 0.25,
            borderWidth: 0,
            color: '#ccc',
            targetOptions: {
              width: 40,
              color: '#ffffff',
            },
            threshold: category.min,
          },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: category.suffix,
          valueDecimals: 2,
          pointFormat: $localize`<b>{point.y}</b> (cible: {point.target})`,
        },
      })
    );
  }
}
