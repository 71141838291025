/** @format */

import { Pipe, PipeTransform } from '@angular/core';

type Data = {
  lastOpenedFailureId?: string | null;
};

@Pipe({
    name: 'equipmentFailureCount',
    standalone: false
})
export class EquipmentFailureCountPipe implements PipeTransform {
  transform(failureCount: number, data?: Data): number {
    if (data?.lastOpenedFailureId) return failureCount + 1;
    return failureCount;
  }
}
