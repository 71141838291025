/** @format */

import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { each, get, head, isNil, last, pick, round } from 'lodash-es';
import moment from 'moment';
import { EquipmentFailure } from '../../../../_classes/equipment-failure/equipment-failure.class';
import { EquipmentFailureElevator } from '../../../../_classes/equipment-failure/equipment-failure.elevator.class';
import { Equipment } from '../../../../_classes/equipment/equipment.class';
import {
  CloseFailureOrigin,
  HumanizeFieldUpdateOrigin,
  OpenFailureOrigin,
} from '../../../../_classes/field-update-origin.class';
import { SereniteTicket } from '../../../../_classes/serenite/serenite-ticket.class';
import { fadeOut } from '../../../../_constants/animations';
import { EquipmentCabinCallNameName } from '../../../../_constants/equipment-cabin-call-name';
import { EquipmentDoorStateName } from '../../../../_constants/equipment-door/equipment-door-state';
import { EquipmentFamily, EquipmentFamilyName } from '../../../../_constants/equipment/equipment-family';
import {
  EquipmentSignatureStatusColor,
  EquipmentSignatureStatusName,
} from '../../../../_constants/equipment-signature-status';
import { EquipmentStatusColor, EquipmentStatusName } from '../../../../_constants/equipment/equipment-status';
import { highchartsOptions } from '../../../../_constants/highcharts';
import { getFloorName } from '../../../../_helpers/equipment.helper';
import { EquipmentFailureElevatorService } from '../../../../_services/equipment-failure/equipment-failure.elevator.service';
import { EquipmentFailureService } from '../../../../_services/equipment-failure/equipment-failure.service';
import { SessionData } from '../../../../app.session.data';
import { ChartComponent } from '../../../chart/chart/chart.component';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';

type DialogData = {
  record: {
    _id: string;
    family: EquipmentFamily;
  };
  equipment: Equipment;
  toggleIsIgnored: (_id: string, isIgnored: boolean) => Promise<any>;
};

type Serenite24Kind = 'sent';
type FormattedChartDataKind =
  | 'equipment-status'
  | 'equipment-signature-status'
  | 'doubt-removal-triggered'
  | 'serenite-24';
// ['y', 'start', 'end', 'color', 'name', 'milestone', 'kind', 'info'],
type FormattedChartDate = [number, number, number, string, string, boolean, FormattedChartDataKind, any?];

@Component({
    selector: 'app-historique-chart.component',
    templateUrl: './historique-chart.component.html',
    styleUrls: ['./historique-chart.component.scss'],
    animations: [fadeOut],
    standalone: false
})
export class HistoriqueChartComponent implements OnInit {
  EquipmentFamilyName = EquipmentFamilyName;

  equipment: Equipment;
  failure: EquipmentFailure | EquipmentFailureElevator;
  position: string;
  door: string;
  doubtRemovals: string;
  openFailureOrigin: string;
  closeFailureOrigin: string;

  sereniteTicket?: SereniteTicket | null;
  sereniteError = false;
  sereniteHistoryColumns = ['datetime', 'type', 'texte'];

  chartOptions: any = {
    chart: { zoomType: 'x' },
    credits: { enabled: false },
    exporting: { enabled: false },
    title: { text: '' },
    xAxis: [
      {
        dateTimeLabelFormats: pick(highchartsOptions[SessionData.$locale.value]?.dateTimeLabelFormats, 'week'),
      },
      { dateTimeLabelFormats: highchartsOptions[SessionData.$locale.value]?.dateTimeLabelFormats },
    ],
    yAxis: {
      type: 'category',
      categories: [
        $localize`Statut de l'équipement`,
        $localize`Statut de la signature`,
        $localize`Levées de doute`,
        $localize`Sérénité 24h24`,
      ],
      labels: {
        align: 'right',
        useHTML: true,
        style: { padding: '0 8px 0 0' },
      },
    },
    tooltip: { useHTML: true, formatter: this.getFormatter() },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: [],
        label: { enabled: false },
      },
    },
    scrollbar: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
      selected: 0,
    },
    series: [
      {
        name: 'field-history',
        keys: ['y', 'start', 'end', 'color', 'name', 'milestone', 'kind', 'info'],
      },
    ],
    plotOptions: { series: { turboThreshold: 10000 } },
  };

  loading = true;

  private dialogRef = inject(MatDialogRef<ConfirmDialogComponent>);
  private equipmentFailureService = inject(EquipmentFailureService);
  private equipmentFailureElevatorService = inject(EquipmentFailureElevatorService);

  constructor(@Inject(MAT_DIALOG_DATA) private data: DialogData) {}

  async ngOnInit() {
    await this.load();
    this.loading = false;
  }

  dissmiss(): void {
    this.dialogRef.close();
  }

  toggleIsIgnoredLoading = false;
  async toggleIsIgnored(isIgnored: boolean) {
    this.toggleIsIgnoredLoading = true;
    try {
      const toggleRes = await this.equipmentFailureService.toggleIgnored(this.failure._id);
      if (!toggleRes) return;
      this.failure.isIgnored = isIgnored;
      await this.data.toggleIsIgnored(this.failure._id, isIgnored);
    } finally {
      this.toggleIsIgnoredLoading = false;
    }
  }

  async loadChart(chart: ChartComponent) {
    chart.setLoading(true);
    const formatted: FormattedChartDate[] = [];
    await this.loadChartData(formatted);
    await this.loadChartElevatorData(formatted);
    chart.setSerieData(formatted);
    chart.setLoading(false);
  }

  // ['y', 'start', 'end', 'color', 'milestone', 'kind', 'info']
  private async loadChartData(formatted: FormattedChartDate[]) {
    const data = await this.equipmentFailureService.getInfo(this.failure._id);
    each(data.equipmentStatuses, (d) => {
      formatted.push([
        0,
        d.start,
        d.end,
        EquipmentStatusColor[d.value],
        EquipmentStatusName[d.value],
        false,
        'equipment-status',
        { origin: d.origin },
      ]);
    });
    each(data.signatureStatuses, (d) => {
      formatted.push([
        1,
        d.start,
        d.end,
        EquipmentSignatureStatusColor[d.value],
        EquipmentSignatureStatusName[d.value],
        false,
        'equipment-signature-status',
        { origin: d.origin },
      ]);
    });
    if (this.failure.notification?.internalOpen) {
      const sentAt = this.failure.notification.internalOpen.message.messageDate.valueOf();
      const acknowledgeAt = this.failure.notification.internalOpen.acknowledgment?.messageDate.valueOf();
      formatted.push([
        3,
        sentAt,
        sentAt,
        acknowledgeAt ? 'var(--app-color-success)' : 'var(--app-color-accent)',
        $localize`Envoi & Acquittement`,
        true,
        'serenite-24',
        { kind: 'sent' as Serenite24Kind, sentAt, acknowledgeAt },
      ]);
    }
  }

  private async loadChartElevatorData(formatted: FormattedChartDate[]) {
    if (this.failure instanceof EquipmentFailureElevator) {
      each(this.failure.triggeredDoubtRemovals, (d) => {
        formatted.push([
          2,
          new Date(d.date).valueOf(),
          new Date(d.date).valueOf(),
          'var(--app-color-secondary)',
          EquipmentCabinCallNameName[d.cabinCall],
          true,
          'doubt-removal-triggered',
        ]);
      });
      try {
        this.sereniteTicket = await this.equipmentFailureElevatorService.getSereniteTicket(this.failure._id);
      } catch (err) {
        this.sereniteError = true;
      }
    }
  }

  private async load() {
    this.equipment = this.data.equipment;
    this.failure = await this.equipmentFailureService.get(this.data.record._id);
    this.buildPosition();
    this.buildDoor();
    this.buildDoubtRemovals();
    this.openFailureOrigin = OpenFailureOrigin(this.failure.openOrigin);
    this.closeFailureOrigin = CloseFailureOrigin(this.failure.closeOrigin);
  }

  private buildPosition() {
    const floors = get(this.equipment, 'metadata.floors');
    if (!floors) return (this.position = ``);
    const end = get(this.failure, 'lastMouvement.endDistance');
    if (isNil(end)) return (this.position = '-');
    const lastDate = get(this.failure, 'lastMouvement.end');
    if (!lastDate) return (this.position = '-');
    return (this.position = $localize`<div>${getFloorName(floors, end) || '?'}</div><span nowrap light>${round(end / 100, 2)} m le ${moment(lastDate).format('L à LT')}</span>`);
  }

  private buildDoor() {
    const lastDate = get(this.failure, 'lastDoorState.end');
    if (!lastDate) return (this.door = '-');
    const state = get(this.failure, 'lastDoorState.state', 0);
    return (this.door = $localize`
          <div>${state ? EquipmentDoorStateName[state] : '?'}</div>
          <span nowrap light>le ${moment(lastDate).format('L à LT')}</span>
          `);
  }

  private buildDoubtRemovals() {
    if (!(this.failure instanceof EquipmentFailureElevator)) return;
    const triggeredDoubtRemovals = get(this.failure, 'triggeredDoubtRemovals');
    if (!triggeredDoubtRemovals?.length) return (this.doubtRemovals = '-');
    const startDate = moment(get(head(triggeredDoubtRemovals), 'date'));
    const endDate = moment(get(last(triggeredDoubtRemovals), 'date'));
    return (this.doubtRemovals = $localize`
    <div nowrap>${triggeredDoubtRemovals.length} levées de doute déclenchées</div>
    <span nowrap light>entre ${moment(startDate).format('LT')} et ${moment(endDate).format('LT')}</span>
    `);
  }

  // ['y', 'start', 'end', 'color', 'name', 'milestone', 'kind', 'info'],
  private getFormatter() {
    return function (this: any): string {
      function formatDate(date: number): string {
        return $localize`<span>${moment(date).format('L [à] LT')}</span>`;
      }

      const duration = !this.point.milestone
        ? `<span style="color:${this.point.color}; padding-left: 8px;">${moment.duration(moment(this.point.start).diff(this.point.end)).humanize()}</span>`
        : '';
      const range = !this.point.milestone
        ? `<div>${formatDate(this.point.start)}<span> - </span>${formatDate(this.point.end)}</div>`
        : '';
      const origin = !this.point.info?.origin
        ? `<div><span>Mis à jour par : </span><span style="font-style: italic;">${HumanizeFieldUpdateOrigin(this.point.origin)}</span></div>`
        : '';

      const header = `<div style="margin-bottom: 4px; width: 100%; display: flex; place-content: center space-between; align-items: center;"><span style="font-size: 1.2em; color:${this.point.color};">${this.point.name}</span>${duration}</div>`;

      switch (this.point.kind as FormattedChartDataKind) {
        case 'equipment-status':
        case 'equipment-signature-status':
          return `${header} ${range} ${origin}`;
        case 'doubt-removal-triggered':
          return `${header} ${formatDate(this.point.start)}`;
        case 'serenite-24':
          const info = this.point.info;
          switch (info.kind as Serenite24Kind) {
            case 'sent':
              let html = header;
              html += $localize`<div>Envoi de la panne à Sérénité 24h24 le ${formatDate(info.sentAt)}</div>`;
              if (info.acknowledgeAt) {
                html += $localize`<div style="color: var(--app-color-success)">Acquittée par Sérénité 24H24 le  ${formatDate(info.acknowledgeAt)}</div>`;
              } else html += $localize`<div style="color: ${this.point.color}">Acquittement en attente</div>`;
              return html;
          }
        default:
          return header;
      }
    };
  }
}
