/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocxViewerComponent } from './docx-viewer.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AwsPipesModule } from '../../_pipes/aws-pipes/aws-pipes.module';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';

@NgModule({
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    NgxDocViewerModule,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    AwsPipesModule,
  ],
  declarations: [DocxViewerComponent],
  exports: [DocxViewerComponent],
})
export class DocxViewerModule {}
