/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map } from 'lodash-es';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { lastValueFrom } from 'rxjs';
import { S3File } from '../../_classes/s3-file.class';
import { fade } from '../../_constants/animations';
import { FileService } from '../../_services/file.service';
import { GalleryDialog } from '../gallery/gallery-dialog/gallery-dialog.dialog';

export interface IPictureGridTile {
  colspan: number;
  rowspan: number;
}

@Component({
    selector: 'app-picture-grid',
    templateUrl: './picture-grid.component.html',
    styleUrls: ['./picture-grid.component.scss'],
    animations: [fade],
    standalone: false
})
export class PicturGridComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  hideShowAllButton = false;

  @Input()
  pictures: S3File[];

  @Input()
  gutterSize = '8px';

  @Input()
  cols = 4;

  @Input()
  tiles = [
    { colspan: 3, rowspan: 1 },
    { colspan: 1, rowspan: 1 },
    { colspan: 1, rowspan: 1 },
    { colspan: 3, rowspan: 1 },
  ];

  intersect = false;

  constructor(private dialog: MatDialog, private fileService: FileService) {}

  ngOnInit() {}

  intersection($event: IntersectionObserverEvent) {
    if ($event.intersect) this.intersect = true;
  }

  private pictureLibraryDialogRef: MatDialogRef<GalleryDialog> | null;
  async openPictureLibrary(index = 0) {
    if (this.pictureLibraryDialogRef) return;
    this.pictureLibraryDialogRef = this.dialog.open(GalleryDialog, {
      height: '100vh',
      maxHeight: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      panelClass: ['dialog-no-padding', 'dialog-no-border-radius'],
      exitAnimationDuration: '300ms',
      data: {
        index,
        images: await Promise.all(map(this.pictures, (picture) => this.fileService.getFileUrl(picture))),
      },
    });
    await lastValueFrom(this.pictureLibraryDialogRef.afterClosed());
    this.pictureLibraryDialogRef = null;
  }
}
