/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isNil, map } from 'lodash-es';
import { Extremes } from '../../../_classes/extremes.class';
import { fadeOut } from '../../../_constants/animations';
import { SensorDataService } from '../../../_services/sensor-data.service';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-weather-chart',
  templateUrl: './weather-chart.component.html',
  styleUrls: ['./weather-chart.component.scss'],
  animations: [fadeOut],
  standalone: false,
})
export class WeatherChartComponent implements OnInit, OnDestroy {
  @Input()
  extremes: Extremes;

  options: any = {
    chart: {
      zoomType: 'x',
    },
    title: '',
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS',
            'openInCloud',
          ],
        },
      },
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false, inputEnabled: false },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              itemWidth: 200,
            },
          },
        },
      ],
    },
    legend: {
      enabled: true,
      verticalAlign: 'top',
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      headerFormat: '<small>{point.key}</small><br/>',
    },
    xAxis: {
      type: 'category',
      tickmarkPlacement: 'on',
      startOnTick: true,
      endOnTick: true,
      events: {
        afterSetExtremes: (event: any) => this.drawWeatherSymbols(),
      },
    },
    yAxis: [
      {
        // temperature axis
        opposite: false,
        allowDecimals: false,
        title: {
          text: '°C',
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '10px',
            color: '#FF3333',
          },
          textAlign: 'left',
          x: -18,
        },
        labels: {
          format: '{value:.1f}',
          style: {
            fontSize: '8px',
            color: '#FF3333',
          },
          x: -3,
        },
        plotLines: [
          {
            // zero plane
            value: 0,
            color: '#BBBBBB',
            width: 1,
            zIndex: 2,
          },
        ],
        maxPadding: 0.3,
        minRange: 8,
        tickInterval: 1,
        gridLineColor: 'rgba(128, 128, 128, 0.1)',
        lineWidth: 1,
      },
      {
        // precipitation axis
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        tickLength: 0,
        minRange: 10,
        min: 0,
      },
      {
        // Air pressure
        allowDecimals: false,
        title: {
          // Title on top of axis
          text: 'hPa',
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '10px',
            color: '#AED6F1',
          },
          textAlign: 'left',
          x: 3,
        },
        labels: {
          format: '{value:.0f}',
          style: {
            fontSize: '8px',
            color: '#AED6F1',
          },
          y: 2,
          x: 3,
        },
        gridLineWidth: 0,
        opposite: true,
        showLastLabel: false,
        lineWidth: 1,
      },
      {
        // Wind
        allowDecimals: false,
        title: {
          // Title on top of axis
          text: 'km/h',
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '10px',
            color: '#85C1E9',
          },
          textAlign: 'left',
          x: 8,
        },
        labels: {
          format: '{value:.0f}',
          style: {
            fontSize: '8px',
            color: '#85C1E9',
          },
          y: 2,
          x: 8,
        },
        gridLineWidth: 0,
        opposite: true,
        showLastLabel: false,
      },
    ],
    series: [
      {
        name: $localize`Température`,
        // data: this.temperatures,
        type: 'spline',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}°C</b><br/>',
        },
        zIndex: 1,
        color: '#FF3333',
        negativeColor: '#48AFE8',
      },
      {
        name: $localize`Précipitation`,
        // data: this.precipitations,
        type: 'column',
        color: '#68CFE8',
        yAxis: 1,
        groupPadding: 0,
        pointPadding: 0,
        grouping: false,
        dataLabels: {
          // enabled: !this.hasPrecipitationError,
          formatter: function () {
            if ((this as any).y > 0) {
              return (this as any).y.toFixed(1);
            }
          },
          style: {
            fontSize: '8px',
            color: 'gray',
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}mm</b><br/>',
        },
      },
      {
        name: $localize`Pression de l'air`,
        color: '#AED6F1',
        // data: this.pressures,
        marker: {
          enabled: false,
        },
        shadow: false,
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}hPa</b><br/>',
        },
        dashStyle: 'shortdot',
        yAxis: 2,
      },
      {
        name: $localize`Vent moyen`,
        color: '#85C1E9',
        type: 'spline',
        // data: this.winds,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: { valueSuffix: ' km/h' },
        yAxis: 3,
      },
      {
        name: $localize`Rafales`,
        color: '#85C1E9',
        type: 'spline',
        dashStyle: 'shortdot',
        // data: this.windgusts,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: { valueSuffix: ' km/h' },
        yAxis: 3,
      },
    ],
  };
  chartComponent: ChartComponent;

  private symbols: string[] = [];
  private precipitations: [number, number][] = [];
  private temperatures: [number, number][] = [];
  private pressures: [number, number][] = [];
  private winds: [number, number][] = [];
  private windgusts: [number, number][] = [];
  private images: Highcharts.SVGElement[] = [];

  constructor(private sensorDataService: SensorDataService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public setExtremes(extremes: Extremes) {
    this.extremes = extremes;
    this.loadData(this.chartComponent);
  }

  async loadData(chartComponent: ChartComponent): Promise<void> {
    if (!chartComponent) return;
    this.chartComponent = chartComponent;
    this.chartComponent.setLoading(true);
    const data = await this.sensorDataService.weartherChart(this.extremes?.start, this.extremes?.end);
    this.temperatures = map(data.temperatures, (d) => [d.x, d.y]);
    this.symbols = map(data.temperatures, (t) => this.weatherIconFromCode(t.weatherCode));
    this.precipitations = map(data.precipitations, (d) => [d.x, d.y]);
    this.pressures = map(data.pressures, (d) => [d.x, d.y]);
    this.winds = map(data.winds, (d) => [d.x, d.y]);
    this.windgusts = map(data.windgusts, (d) => [d.x, d.y]);
    this.chartComponent.setSerieData(this.temperatures, 0);
    this.chartComponent.setSerieData(this.precipitations, 1);
    this.chartComponent.setSerieData(this.pressures, 2);
    this.chartComponent.setSerieData(this.winds, 3);
    this.chartComponent.setSerieData(this.windgusts, 4);
    if (this.extremes) this.chartComponent.setExtremes(this.extremes, true);
    this.chartComponent.setLoading(false);
  }

  private drawWeatherSymbols(): void {
    if (!this.chartComponent) return;
    this.images.forEach((i) => i.destroy());
    this.images = [];
    this.chartComponent.getPoints()?.forEach((point: any, i) => {
      if (isNil(point.plotX)) return;
      const x = point.plotX + this.chartComponent.chart!.plotLeft - 15;
      if (x < 0) return;
      const y = point.plotY + this.chartComponent.chart!.plotTop - 30;
      this.images.push(
        this.chartComponent.chart!.renderer.image(this.symbols[i], x, y, 30, 30).attr({ zIndex: 5 }).add(),
      );
    });
  }

  private weatherIconFromCode(code: number): string {
    let icon: string;
    switch (code) {
      case 113:
        icon = 'wi-day-sunny';
        break;
      case 116:
        icon = 'wi-cloud';
        break;
      case 119:
        icon = 'wi-cloudy';
        break;
      case 122:
        icon = 'wi-fog';
        break;
      case 143:
        icon = 'wi-sprinkle';
        break;
      case 176:
        icon = 'wi-sprinkle';
        break;
      case 179:
        icon = 'wi-snow';
        break;
      case 182:
        icon = 'wi-sleet';
        break;
      case 185:
        icon = 'wi-sleet';
        break;
      case 200:
        icon = 'wi-storm-showers';
        break;
      case 227:
        icon = 'wi-snow';
        break;
      case 230:
        icon = 'wi-snow-wind';
        break;
      case 248:
        icon = 'wi-fog';
        break;
      case 260:
        icon = 'wi-fog';
        break;
      case 263:
        icon = 'wi-sprinkle';
        break;
      case 266:
        icon = 'wi-sprinkle';
        break;
      case 281:
        icon = 'wi-sleet';
        break;
      case 284:
        icon = 'wi-sleet';
        break;
      case 293:
        icon = 'wi-sprinkle';
        break;
      case 296:
        icon = 'wi-sprinkle';
        break;
      case 299:
        icon = 'wi-showers';
        break;
      case 302:
        icon = 'wi-showers';
        break;
      case 305:
        icon = 'wi-rain';
        break;
      case 308:
        icon = 'wi-rain';
        break;
      case 311:
        icon = 'wi-sleet';
        break;
      case 314:
        icon = 'wi-sleet';
        break;
      case 317:
        icon = 'wi-sleet';
        break;
      case 320:
        icon = 'wi-sleet';
        break;
      case 323:
        icon = 'wi-snow';
        break;
      case 326:
        icon = 'wi-snow';
        break;
      case 329:
        icon = 'wi-snow';
        break;
      case 332:
        icon = 'wi-snow';
        break;
      case 335:
        icon = 'wi-snow';
        break;
      case 338:
        icon = 'wi-snow';
        break;
      case 350:
        icon = 'wi-hail';
        break;
      case 353:
        icon = 'wi-sprinkle';
        break;
      case 356:
        icon = 'wi-rain';
        break;
      case 359:
        icon = 'wi-rain';
        break;
      case 362:
        icon = 'wi-sleet';
        break;
      case 365:
        icon = 'wi-sleet';
        break;
      case 368:
        icon = 'wi-snow';
        break;
      case 371:
        icon = 'wi-snow';
        break;
      case 374:
        icon = 'wi-sleet';
        break;
      case 377:
        icon = 'wi-hail';
        break;
      case 386:
        icon = 'wi-storm-showers';
        break;
      case 389:
        icon = 'wi-thunderstorm';
        break;
      case 392:
        icon = 'wi-storm-showers';
        break;
      case 395:
        icon = 'wi-storm-showers';
        break;
      default:
        return 'unknown';
    }
    return `assets/icons/weather-code-icon/${icon}.svg`;
  }
}
