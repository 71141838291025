<!-- @format -->

<div *ngIf="ready; else loadingTmpl" id="container" fxLayout="column">
  <mat-toolbar mat-dialog-header id="header">
    <div fxLayoutAlign="space-between center" fxFlex="100" [style.height.%]="100">
      <div fxLayoutAlign="start center" [style.height.%]="100">
        <button mat-dialog-back-btn mat-button vfill mat-dialog-close>
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="medium">chevron_left</mat-icon>
            <span color="medium" light uppercase i18n>Retour</span>
          </div>
        </button>

        <button id="status" mat-icon-button vfill [color]="EquipmentStatusColor[elevator.status]">
          <mat-icon>{{EquipmentStatusIcon[elevator.status]}}</mat-icon>
        </button>

        <div fxLayout="column" fxLayoutAlign="center start" [style.margin-left.px]="16">
          <h1 id="reference" color="primary" fxLayoutGap="8px">
            <span light i18n>Ascenseur</span>
            <span>{{elevator.reference}}</span>
          </h1>
          <span id="address" color="primary" cursor-pointer underline (click)="mapBlock.open()">
            {{elevator.location.address.number}} {{elevator.location.address.street}},
            <span light>{{elevator.location.address.postalCode}} {{elevator.location.address.city}}</span>
          </span>
        </div>
      </div>

      <div fxLayoutAlign="start center" [style.height.%]="100">
        <div fxLayout="column" fxLayoutAlign="center center" class="header-info-block">
          <span class="sup" color="primary" i18n>
            <span light>État de l'équipement : </span> {{elevator.condition | number:'0.1-1'}}</span
          >
          <app-star-rate [value]="elevator.condition" [style.height.px]="30"></app-star-rate>
        </div>

        <button mat-button class="header-info-block" [tooltip]="failureTooltip" tooltipTrigger="click">
          <div fxLayout="column" fxLayoutAlign="center center">
            <span class="sup" color="primary" light i18n>
              Panne{ elevator.failureCount | equipmentFailureCount:elevator, plural, =0{} one{} other{s} }
            </span>
            <app-failure-count-label
              class="info"
              [value]="elevator.failureCount | equipmentFailureCount:elevator"
            ></app-failure-count-label>
            <ng-template #failureTooltip>
              <app-equipment-failure-info [value]="elevator"></app-equipment-failure-info>
            </ng-template>
          </div>
        </button>

        <button mat-button class="header-info-block" [tooltip]="disponibilityTooltip" tooltipTrigger="click">
          <div fxLayout="column" fxLayoutAlign="center center">
            <span class="sup" color="primary" light i18n>Taux de disponibilité</span>
            <app-disponibility-rate-label
              class="info"
              [value]="elevator.disponibilityRate| equipmentDisponibilityRate:elevator"
            />
            <ng-template #disponibilityTooltip>
              <app-equipment-disponibility-info [value]="elevator"></app-equipment-disponibility-info>
            </ng-template>
          </div>
        </button>

        <button mat-dialog-close-btn mat-button vfill mat-dialog-close>
          <div fxLayout="column" fxLayoutAlign="center center">
            <span class="sup" color="medium" light i18n>Fermer</span>
            <mat-icon color="medium" fontSet="material-icons-outlined">cancel_outline</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <ng-scrollbar #scrollbar id="content" visibility="hover" orientation="vertical">
    <div id="inner-content" fxLayout="column" fxLayoutGap="16px">
      <div id="controls" fxLayoutAlign="start center" fxLayoutGap="16px">
        <button
          *ngIf="authService.user?.isAdministrator || elevator.metadata?.floorInitialization?.active"
          mat-flat-button
          class="control"
          i18n-matTooltip
          matTooltip="Initialisation / consolidation des niveaux"
          (click)="showControlInfo('initialization')"
        >
          <div class="control-kind" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <ng-container *ngIf="elevator.metadata?.floorInitialization?.active">
              <span *ngIf="elevator.status === EquipmentStatus.INITIALIZING" color="primary" i18n>
                Initialisation :
              </span>
              <span *ngIf="elevator.status !== EquipmentStatus.INITIALIZING" color="primary" i18n>
                Consolidation :
              </span>
              <mat-icon color="secondary" class="spin">sync</mat-icon>
            </ng-container>
            <ng-container *ngIf="!elevator.metadata?.floorInitialization?.active">
              <span color="primary" i18n>Initialisé</span>
              <mat-icon color="success">check</mat-icon>
            </ng-container>
          </div>
        </button>

        <button
          *ngIf="elevator.metadata?.signature"
          mat-flat-button
          class="control"
          [matTooltip]="EquipmentSignatureStatusName[elevator.metadata!.signature!.status]"
          (click)="showControlInfo('signature')"
        >
          <div class="control-kind" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span color="primary" i18n>Signature :</span>
            <mat-icon [style.color]="EquipmentSignatureStatusColor[elevator.metadata!.signature!.status]">
              {{EquipmentSignatureStatusIcon[elevator.metadata!.signature!.status]}}
            </mat-icon>
          </div>
        </button>

        <ng-container *ngFor="let component of components">
          <button
            *ngIf="componentControls | includes:component.kind"
            mat-flat-button
            class="control"
            (click)="showControlInfo(component.kind)"
          >
            <div class="control-kind" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <span color="primary">{{SesioNodeComponentKindControlName[component.kind]}}</span>
              <mat-icon [style.color]="SesioNodeComponentStatusColor[component.status]">
                {{SesioNodeComponentStatusIcon[component.status]}}
              </mat-icon>
            </div>
          </button>
        </ng-container>

        <button *ngIf="node" mat-flat-button class="control" (click)="showControlInfo('network')">
          <div class="control-kind" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span color="primary" i18n>Réseaux</span>
            <mat-icon *ngIf="node?.connectivity" [style.color]="ConnectivityStatusColor[node.connectivity.status]">
              {{ConnectivityStatusIcon[node.connectivity.status]}}
            </mat-icon>
          </div>
        </button>

        <button
          #actionsMenuTrigger="matMenuTrigger"
          id="actions-menu"
          mat-mini-fab
          color="secondary"
          [matMenuTriggerFor]="actionsMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu" class="elevator-info-actions">
          <span mat-menu-item [disabled]="true" i18n>Modifier le statut de l'appareil</span>

          <button
            *ngIf="elevator?.status !== EquipmentStatus.MAINTENANCE"
            mat-menu-item
            color="maintenance"
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.MAINTENANCE); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir déclarer l'appareil en maintenance ?"
            i18n-confirmMessage
            confirmMessage="Attention, l'appareil ne sera plus supervisé, les pannes ne seront plus détectées."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.MAINTENANCE]}} </mat-icon>
            <span color="primary" i18n>Déclarer l'appareil en maintenance</span>
          </button>
          <button
            *ngIf="elevator?.status === EquipmentStatus.MAINTENANCE"
            mat-menu-item
            color="maintenance"
            reversed-color
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.OK); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir clôturer la maintenance ?"
            i18n-confirmMessage
            confirmMessage="Attention, la supervision de l'appareil va redémarrer, veuillez vous assurer que les portes sont fermées et que l'appareil n'est pas entre deux niveaux."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.MAINTENANCE]}} </mat-icon>
            <span i18n>Clôturer la maintenance</span>
          </button>

          <button
            *ngIf="elevator?.status !== EquipmentStatus.REPARATION"
            mat-menu-item
            color="reparation"
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.REPARATION); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir déclarer l'appareil en dépannage ?"
            i18n-confirmMessage
            confirmMessage="Attention, l'appareil ne sera plus supervisé, les pannes ne seront plus détectées."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.REPARATION]}} </mat-icon>
            <span color="primary" i18n>Déclarer l'appareil en dépannage</span>
          </button>
          <button
            *ngIf="elevator?.status === EquipmentStatus.REPARATION"
            mat-menu-item
            color="reparation"
            reversed-color
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.OK); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir clôturer le dépannage ?"
            i18n-confirmMessage
            confirmMessage="Attention, la supervision de l'appareil va redémarrer, veuillez vous assurer que les portes sont fermées et que l'appareil n'est pas entre deux niveaux."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.REPARATION]}} </mat-icon>
            <span i18n>Clôturer le dépannage</span>
          </button>

          <button
            *ngIf="elevator?.status !== EquipmentStatus.OUT_OF_ORDER"
            mat-menu-item
            color="out-of-order"
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.OUT_OF_ORDER); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir déclarer l'appareil en panne ?"
            i18n-confirmMessage
            confirmMessage="Attention, l'appareil ne sera plus supervisé, les pannes ne seront plus détectées."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.OUT_OF_ORDER]}} </mat-icon>
            <span color="primary" i18n>Déclarer l'appareil en panne</span>
          </button>
          <button
            *ngIf="elevator?.status === EquipmentStatus.OUT_OF_ORDER"
            mat-menu-item
            color="out-of-order"
            reversed-color
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.OK); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir clôturer la panne ?"
            i18n-confirmMessage
            confirmMessage="Attention, la supervision de l'appareil va redémarrer, veuillez vous assurer que les portes sont fermées et que l'appareil n'est pas entre deux niveaux."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.OUT_OF_ORDER]}} </mat-icon>
            <span i18n>Clôturer la panne</span>
          </button>

          <button
            *ngIf="elevator?.status !== EquipmentStatus.STOPPED"
            mat-menu-item
            color="stopped"
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.STOPPED); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir déclarer l'appareil à l'arrêt ?"
            i18n-confirmMessage
            confirmMessage="Attention, l'appareil ne sera plus supervisé, les pannes ne seront plus détectées."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.STOPPED]}} </mat-icon>
            <span color="primary" i18n>Déclarer l'appareil à l'arrêt</span>
          </button>
          <button
            *ngIf="elevator?.status === EquipmentStatus.STOPPED"
            mat-menu-item
            color="stopped"
            reversed-color
            [disabled]="updatingEquipmentStatus"
            confirm
            (confirmed)="updateEquipmentStatus(EquipmentStatus.OK); actionsMenuTrigger.closeMenu()"
            i18n-confirmTitle
            confirmTitle="Êtes vous sûr de vouloir remettre l'appareil en service ?"
            i18n-confirmMessage
            confirmMessage="Attention, la supervision de l'appareil va redémarrer, veuillez vous assurer que les portes sont fermées et que l'appareil n'est pas entre deux niveaux."
          >
            <mat-icon> {{EquipmentStatusIcon[EquipmentStatus.STOPPED]}} </mat-icon>
            <span i18n>Remettre l'appareil en service</span>
          </button>

          <!-- <mat-divider></mat-divider>

          <button mat-menu-item>
            <mat-icon color="anomaly">gpp_maybe</mat-icon>
            <span i18n>Déclarer une anomalie</span>
          </button> -->

          <mat-divider></mat-divider>

          <div
            *ngIf="elevator.metadata?.signature?.status !== EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER"
            [matTooltipDisabled]="![EquipmentStatus.MAINTENANCE, EquipmentStatus.REPARATION, EquipmentStatus.STOPPED].includes(elevator.status)"
            i18n-matTooltip
            [matTooltip]="'Quand votre appareil est dans le statut ' + EquipmentStatusName[elevator.status] + ' vous ne pouvez pas démarrer un processus de levée de doute, veuillez d\'abord clôturer le statut ' +
            EquipmentStatusName[elevator.status] + '.'"
          >
            <button
              mat-menu-item
              [disabled]=" updatingEquipmentStatus || [EquipmentStatus.MAINTENANCE, EquipmentStatus.REPARATION,
            EquipmentStatus.STOPPED].includes(elevator.status)"
              confirm
              (confirmed)="updateEquipmentSignatureStatus(); actionsMenuTrigger.closeMenu()"
              i18n-confirmTitle
              confirmTitle="Êtes vous sûr de vouloir démarrer un processus de levée de doute ?"
              i18n-confirmMessage
              confirmMessage="Cette action va enclencher une série de processus qui vérifiera si votre ascenseur est en fonctionnement ou en panne.</br>
            À l' issue de ces processus le statut de votre ascenseur sera mis à jour.</br>
            Ce processus peut durer jusqu'à 3 minutes."
            >
              <mat-icon [style.color]="EquipmentSignatureStatusColor[EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]">
                {{EquipmentSignatureStatusIcon[EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]}}
              </mat-icon>
              <span i18n>Démarrer un processus de levée de doute</span>
            </button>
          </div>

          <div
            *ngIf="elevator.metadata?.signature?.status === EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER"
            i18n-matTooltip
            matTooltip="Un processus de levée de doute est déjà en cours, veuillez attendre qu'il se termine avant de démarrer un nouveau processus de levée de doute."
          >
            <button mat-menu-item disabled>
              <mat-icon [style.color]="EquipmentSignatureStatusColor[EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]">
                {{EquipmentSignatureStatusIcon[EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]}}
              </mat-icon>
              <span nowrap i18n
                >Processus de levée de doute en cours :
                <app-count-up
                  decimalPlaces="0"
                  i18n-suffix
                  suffix=" %"
                  [value]="(signatureSince / (1000 * 60 * 3)) * 100"
                ></app-count-up
              ></span>
            </button>
          </div>
        </mat-menu>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start" [style.width.%]="100" [style.padding-left.px]="16">
        <div fxLayout="column" fxLayoutGap="16px" [style.width.%]="100">
          <span class="title" color="primary" i18n>Fréquentation <span light>par niveau</span></span>
          <app-chart
            kind="highstock"
            [options]="floorChartOptions"
            (ready)="loadFloorChartData($event)"
            [style.height.px]="285"
          >
          </app-chart>

          <span class="title" *ngIf="metadata" color="primary" fxLayoutGap="4px">
            <span i18n>Trafic</span>
            <span light>:</span>
            <app-count-up light [value]="metadata.movementCount"></app-count-up>
            <span light i18n>Mouvement{metadata.movementCount, plural, =0{} one{} other{s}}</span>
            <span light
              >(<span
                ><app-count-up
                  light
                  [value]="metadata.distanceCount / 100000"
                  [decimalPlaces]="2"
                  i18n-suffix
                  suffix=" km"
                ></app-count-up></span
              >).</span
            >
            <span light>Moyenne / Jour</span>
            <app-count-up light [value]="metadata.movementDailyAvg"></app-count-up>
            <span light>
              (<app-count-up
                light
                [value]="metadata.distanceDailyAvg ? metadata.distanceDailyAvg / 100000: metadata.distanceDailyAvg"
                i18n-suffix
                suffix=" km"
              ></app-count-up
              >).
            </span>
          </span>
          <app-mouvement-chart id="mouvement-chart" [equipmentId]="elevator._id"></app-mouvement-chart>
        </div>

        <div fxLayout="column" fxLayoutGap="4px">
          <div fxLayoutAlign="space-between end">
            <div fxLayout="column">
              <div class="title" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
                <span medium nowrap color="primary" i18n>Statut</span>
                <span>:</span>
                <span medium nowrap [style.color]="elevator.status| equipmentStatus:'color'"
                  >{{EquipmentStatusName[elevator.status]}}</span
                >
                <span
                  id="floor-info-label"
                  light
                  nowrap
                  [style.color]="elevator.status | equipmentStatus:'color'"
                  innerHTML="({{elevator | elevatorFloor:currentFloorIndex }})"
                ></span>
              </div>
              <div fxLayoutAlign="space-between center" fxLayoutGap="8px" [style.width.px]="480">
                <span nowrap color="primary" fxLayoutAlign="start center" fxLayoutGap="4px">
                  <span light i18n>Dernier mouvement</span>
                  <span>:</span>
                  <ng-container *ngIf="metadata.lastMouvement?.end">
                    <span italic lowercase i18n>
                      Depuis {{metadata.lastMouvement!.end | humanizeDuration:{largest: 1} }}
                    </span>
                    <span light [style.font-size.px]="12" [style.line-height.em]="1">
                      ( {{metadata.lastMouvement!.end | toDate | date:'short' }} )
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!metadata?.lastMouvement?.end"><span light>-</span></ng-container>
                </span>
              </div>
              <div
                *ngIf="hasDoorControl && lastDoorStates?.length"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                [style.width.px]="480"
              >
                <span
                  *ngFor="let doorState of lastDoorStates"
                  nowrap
                  class="door-state"
                  color="primary"
                  [innerHTML]="EquipmentDoorStateNameLabel[doorState.doorName][doorState.state] | safe:'html'"
                >
                </span>
              </div>
            </div>
            <div id="floors-title" fxLayout="column" fxLayoutAlign="start start">
              <button
                *ngIf="!view3d"
                id="view-switch"
                mat-icon-button
                size="small"
                (click)="view3d = !view3d"
                i18n-matTooltip
                matTooltip="Passer à la vue 3D"
                color="primary"
              >
                <mat-icon svgIcon="3d"></mat-icon>
              </button>
              <button
                *ngIf="view3d"
                id="view-switch"
                mat-icon-button
                size="small"
                (click)="view3d = !view3d"
                i18n-matTooltip
                matTooltip="Passer à la vue 2D"
                color="secondary"
              >
                <mat-icon svgIcon="2d"></mat-icon>
              </button>
            </div>
          </div>
          <app-elevator-live-3d
            id="floors-view"
            *ngIf="view3d"
            [elevator]="elevator"
            [currentSpeed]="currentSpeed"
            [direction]="direction"
            [currentFloorIndex]="currentFloorIndex"
            [doorState]="doorState"
          ></app-elevator-live-3d>
          <app-elevator-live-2d
            id="floors-view"
            *ngIf="!view3d"
            [elevator]="elevator"
            [components]="components"
          ></app-elevator-live-2d>
        </div>
      </div>

      <app-chart-range-selector
        #statistics
        [options]="statisticsPanelOptions"
        (changed)="setExtremes($event)"
        id="statistics"
        (afterExpand)="scrollbar.scrollToElement('#statistics')"
      >
        <div
          content
          class="block"
          fxLayout="row wrap"
          fxLayoutAlign="space-between start"
          [style.min-height.px]="400"
          [style.padding-top.px]="16"
        >
          <div
            class="row-block"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [style.width.px]="360"
          >
            <span class="title" color="primary" i18n>Fréquentations en %</span>
            <app-chart [options]="attendancePercentageChartOptions" (ready)="loadAttendancePercentageChartData($event)">
            </app-chart>
          </div>
          <div
            class="row-block"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [style.width.px]="800"
          >
            <span class="title" color="primary" i18n>Fréquentation en nombre d'arrêts</span>
            <app-chart [options]="attendanceChartOptions" (ready)="loadAttendanceChartData($event)"> </app-chart>
          </div>
          <div
            class="row-block"
            fxLayout="column"
            fxLayoutAlign="space-between center"
            fxLayoutGap="16px"
            [style.width.px]="480"
          >
            <span class="title" color="primary" i18n>Vitesse moyenne de la cabine</span>
            <app-chart [options]="speedAverageChartOptions" (ready)="loadSpeedAverageChartData($event)"> </app-chart>
          </div>
        </div>

        <div
          content
          class="block"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="16px"
          [style.padding-top.px]="16"
        >
          <mat-expansion-panel
            class="row-block"
            #histoDoor
            id="histo-door"
            (afterExpand)="scrollbar.scrollTo({ top: histoDoorRef.nativeElement.offsetTop + statisticsRef.nativeElement.offsetTop - 53 })"
            [disabled]="!hasDoorControl"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="title" color="primary" i18n>
                  <span light>Temps des </span> ouvertures / fermeture des portes
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <app-chart
                kind="highstock"
                [options]="doorHistoryChartOptions"
                (ready)="loadDoorHistoryChartData($event)"
              >
              </app-chart>
            </ng-template>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="row-block"
            #histoStatus
            id="histo-status"
            (afterExpand)="scrollbar.scrollTo({ top: histoStatusRef.nativeElement.offsetTop + statisticsRef.nativeElement.offsetTop - 53 })"
          >
            <mat-expansion-panel-header>
              <span class="title" color="primary" i18n> <span light>Historique des </span> statuts </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <!-- <app-field-history-chart #rangeElement [entityId]="elevator._id" field="status"
                [categories]="EquipmentStatusChartOptions" (over)="histoStatusOver($event)"
                [style.width.%]="100"></app-field-history-chart> -->
              <app-multi-field-history-chart
                #rangeElement
                [entities]="statusEntities"
                field="status"
                [categories]="statusEntityCategories"
                (over)="histoStatusOver($event)"
                [style.width.%]="100"
              ></app-multi-field-history-chart>
            </ng-template>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="row-block"
            #histoFailure
            id="histo-failure"
            (afterExpand)="scrollbar.scrollTo({ top: histoFailureRef.nativeElement.offsetTop + statisticsRef.nativeElement.offsetTop - 53 })"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="title" color="primary" i18n> <span light>Historique des </span> pannes </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <app-failure-datagrid #rangeElement [equipment]="elevator"></app-failure-datagrid>
              <ng-template #loadingTmpl>
                <app-loader id="loader" @fade absolute [diameter]="60"></app-loader>
              </ng-template>
            </ng-template>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="row-block"
            #histoMouvement
            id="histo-mouvement"
            (afterExpand)="scrollbar.scrollTo({ top: histoMouvementRef.nativeElement.offsetTop + statisticsRef.nativeElement.offsetTop - 53 })"
          >
            <mat-expansion-panel-header>
              <span class="title" color="primary" i18n> <span light>Historique des </span> mouvements </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <app-chart
                kind="highstock"
                [options]="mouvementHistoryChartOptions"
                (ready)="loadMouvementHistoryChartData($event)"
              >
              </app-chart>
            </ng-template>
          </mat-expansion-panel>
        </div>
      </app-chart-range-selector>

      <mat-expansion-panel
        id="service-book"
        (afterExpand)="scrollbar.scrollToElement('#service-book')"
        [disabled]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span i18n>Carnet d'entretien</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent> </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="elevator.pictures?.length"
        id="picture-library"
        (afterExpand)="scrollbar.scrollToElement('#picture-library')"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span i18n>Photothèque</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-picture-grid
            class="block"
            [hideShowAllButton]="true"
            [cols]="cols"
            [tiles]="tiles"
            [pictures]="elevator.pictures"
          ></app-picture-grid>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="elevator.documents?.length"
        id="documents"
        (afterExpand)="scrollbar.scrollToElement('#documents')"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span i18n>Documents</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div fxLayout="row wrap" [style.padding.px]="4">
            <app-entity-document-viewer
              *ngFor="let document of elevator.documents"
              [document]="document"
            ></app-entity-document-viewer>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel #mapBlock no-padding id="map" (afterExpand)="scrollbar.scrollToElement('#map')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div fxLayoutGap="4px">
              <span>{{elevator.location.address.number}}</span>
              <span>{{elevator.location.address.street}}</span>
              <span light>{{elevator.location.address.postalCode}}</span>
              <span light>{{elevator.location.address.city}}</span>
            </div>
          </mat-panel-title>
          <mat-panel-description fxLayoutAlign="end center" [style.flex-grow]="1">
            <a
              target="_blank"
              href="https://www.google.fr/maps/search/?api=1&query={{elevator.location.address.formatted}}"
              [style.text-decoration]="'none'"
              (click)="$event.stopPropagation()"
            >
              <span color="secondary" i18n>Voir sur Google Map</span>
            </a>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-map [options]="{ maxZoom: 20 }" (ready)="showMarker($event)" [style.height.px]="500"></app-map>
        </ng-template>
      </mat-expansion-panel>
    </div>
    <button id="footer" mat-button mat-dialog-close><span light uppercase i18n>Fermer la page</span></button>
  </ng-scrollbar>
</div>

<app-loader *ngIf="loading" [diameter]="80" absolute backdrop> </app-loader>

<ng-template #loadingTmpl>
  <app-loader @fadeOut [diameter]="80" absolute backdrop></app-loader>
</ng-template>
